import React, { useContext } from "react";
import { Route, Routes } from 'react-router-dom';

import { AppContext} from '../../AppContext';


import QAContracts from "./QAContracts";
import QADemo from "./QADemo";

import '../css/GPTApp.css';


function QA() {

    const { userID, userStatus, loading } = useContext(AppContext).contextValue;
    if (loading) {
        return <div></div>;
    }

    return (
            <div>
                <Routes>
                    <Route
                    path="*"
                    element={userStatus === 'demo' ? <QADemo /> : <QAContracts userID= {userID}/>}
                    />
                </Routes>
            </div>
    );
}

export default QA; 