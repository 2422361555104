import React, { useContext, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { AppContext } from '../../AppContext'
import {
    ref,
    storage,
    getSignedUrlCallable,
    listAll,
    db,
    setDoc,
    doc,
    getDoc,
    updateDoc,
    collection,
    runTransaction,
    serverTimestamp,
    arrayUnion,
} from '../../Firebase';
import AlertDialog from "../utils/AlertDialog";
import Feedback from "../service/Feedback";
import '../css/GPTMenu.css';
import '../css/GPTApp.css';
import { FaRobot, FaUser, FaPaperPlane, FaThumbsUp, FaThumbsDown, FaCopy } from 'react-icons/fa';
import qdt1 from '../../assets/qtig/qdt1.png';

function QADemo() {

    const { userID, projectName, csvExists, userStatus } = useContext(AppContext).contextValue;

    const [query, setQuery] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [prediction, setPrediction] = useState('');
    const [question, setQuestion] = useState('');

    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    // Fetch the file CSV containing the contract embedded ----------------------------
    const [fileName, setFileName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [feedback, setFeedback] = useState(null);

    const fetchCsvFile = async (projectName) => {
        
        try {
            const storageRef = ref(storage, `users/${userID}/${projectName}`);
            const files = await listAll(storageRef);
            const csvFile = files.items.find((file) => file.name.endsWith('.csv'));

            if (csvFile) {
                const filePath = csvFile.fullPath;
                const result = await getSignedUrlCallable({ filePath });
                const signedUrl = result.data.url;
                setFileName(signedUrl);  // update state for use elsewhere
                return signedUrl;  // return for immediate use
            } else {
                return null;
            }

        } catch (error) {
            console.error("Error fetching CSV file:", error);
            setErrorMessage("Error reading processed document: " + error.message);
            setShowErrorDialog(true);
            return null;
        }
    };
     

    // Constructing LIST OF QUESTIONS ------------------------------------------------------

    // 1. Fetch the *Standard Questions* stored in Firebase global data --------------------
    const [standardQuestions, setStandardQuestions] = useState([]);

    useEffect(() => {
        const fetchStandardQuestions = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setStandardQuestions(data.standardQuestions);
            } else {
                console.log("No such document!");
            }
        };

        fetchStandardQuestions().catch((error) => {
            console.log("Error fetching standard questions: ", error);
        });
    }, []);

    // 2. Fetch the *User Questions* stored in Firebase user qas  --------
    const [userQuestions, setUserQuestions] = useState([]);

    useEffect(() => {
        const fetchUserQuestions = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, 'users', userID, 'contracts', projectName);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.qas) {
                    setUserQuestions(data.qas.map(qa => qa.query));
                }
            }
        };

        fetchUserQuestions().catch(console.error);
    }, []);

    // 3. Merge *Standard* and *User* questions into *All Questions*
    const standardFlatQuestions = standardQuestions.flatMap((category) => category.questions);
    const allQuestions = [...new Set([...standardFlatQuestions, ...userQuestions])];

    // END list of questions-----------------------------------------------------------------

    // Fetch the User's *Questions and Answers* stored in Firebase and save in *qas* --------
    const [qas, setQas] = useState([]);

    useEffect(() => {
        const fetchQAs = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, "users", userID, "contracts", projectName);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                setQas(docSnapshot.data().qas || []);
            }
        };
        fetchQAs();
    }, []); 

    // Fetching Pricing stored in Firebase global data --------------------
    const [modelPrices, setModelPrices] = useState({});

    useEffect(() => {
        const fetchPricingData = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const selectedModelPricing = data.pricing.find(p => p.model === "GPT3.5"); // or whatever model you need

                if (selectedModelPricing) {
                    setModelPrices(selectedModelPricing.prices);
                }
            } else {
                console.log("No such contract!");
            }
        };

        fetchPricingData().catch((error) => {
            console.log("Error fetching pricing: ", error);
        });
    }, []);


    //------------------------------
    // 1. Validation of input
    const validateInput = (projectName, query) => {
        if (!projectName || !query) {
            throw new Error("Missing filename or query.");
        }
    };

    // 2. Checking for existing answers
    const checkExistingAnswer = (qas, query) => {
        return qas.find(qa => qa.query === query);
    };

    // 3. Fetching data from your API
    const fetchDataFromAPI = async (projectName, query) => {
        const fileName = await fetchCsvFile(projectName);
        const url = "https://qaqtig-uwwawdhkgq-ew.a.run.app/api";
        const data = { file: fileName, question: query };

        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const text = await response.text();
            throw new Error('Server response was not ok, please try again: ' + text);
        }

        return await response.json();
    };

    // 4. Registering Token transactions

    const updateTokens = async (result, userID) => {
        return runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, "users", userID);
            const contractDocRef = doc(userDocRef, "contracts", projectName);
            const transactionRef = doc(collection(userDocRef, "tokenHistory"));

            // Step 4.1: Read all the required documents
            const userDocSnapshot = await transaction.get(userDocRef);
            const contractDocSnapshot = await transaction.get(contractDocRef);
            
            // Step 4.2: Calculate total token cost for this transaction
            const { emb, input, output } = modelPrices;
            const totalTokensCost = ((result.tokens.qasTokensE * emb) + (result.tokens.qasTokensI * input) + (result.tokens.qasTokensO * output)) * 0.001;

            // Step 4.3: Compute the new values for contractDoc
            // Update User Level Totals
            transaction.update(userDocRef, {
                totalAmount: (userDocSnapshot.data().totalAmount || 0) + totalTokensCost,
                tokensE_Total: (userDocSnapshot.data().tokensE_Total || 0) + result.tokens.qasTokensE,
                tokensI_Total: (userDocSnapshot.data().tokensI_Total || 0) + result.tokens.qasTokensI,
                tokensO_Total: (userDocSnapshot.data().tokensO_Total || 0) + result.tokens.qasTokensO,
            });

            // Update Contract Level Totals
            transaction.update(contractDocRef, {
                qasTokensE: (contractDocSnapshot.data().qasTokensE || 0) + result.tokens.qasTokensE,
                qasTokensI: (contractDocSnapshot.data().qasTokensI || 0) + result.tokens.qasTokensI,
                qasTokensO: (contractDocSnapshot.data().qasTokensO || 0) + result.tokens.qasTokensO,
            });

            // Store Token Usage History
            transaction.set(transactionRef, {
                timeLog: serverTimestamp(),
                tokensE: result.tokens.qasTokensE,
                tokensI: result.tokens.qasTokensI,
                tokensO: result.tokens.qasTokensO
            });
        });
    };

    // 5. Saving new Q&A
    const updateQA = async (result, query, userID) => {
        return runTransaction(db, async (transaction) => {
            const contractDocRef = doc(db, "users", userID, "contracts", projectName); 
            const contractDocSnapshot = await transaction.get(contractDocRef);
            transaction.update(contractDocRef, {
                qas: [...(contractDocSnapshot.data().qas || []), { query, prediction: result.answer }],
            });
        });
    };


    //Handle the input changes ------------------------------
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    //--------------------------------

    // Handle Submit button sending the question ----------------------


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setQuestion(query);

        try {
            validateInput(projectName, query);
            const existingAnswer = checkExistingAnswer(qas, query);

            if (existingAnswer) {
                setPrediction(existingAnswer.prediction);
                setFeedback(existingAnswer.feedback);
            } else {
                const result = await fetchDataFromAPI(projectName, query);
                setPrediction(result.answer);
                setFeedback(null);
                await updateTokens(result, userID);
                await updateQA(result, query, userID);
                setQas(prevQas => [...prevQas, { query, prediction: result }]);
            }

        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Error: " + error.message);
            setShowErrorDialog(true);
        } finally {
            setIsProcessing(false);
            setQuery('');
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(prediction);
        setShowErrorDialog(true);
        setErrorMessage('Answer copied to the clipboard!');
    };

    // Function to save feedback in Firestore
    const saveFeedback = async (userID, projectName, queryText, feedback) => {
        try {
            await runTransaction(db, async (transaction) => {
                const contractDocRef = doc(db, "users", userID, "contracts", projectName);
                const contractDocSnapshot = await transaction.get(contractDocRef);

                // Get the current Q&A array
                const qas = contractDocSnapshot.data().qas || [];

                // Find the query index based on the query text
                const queryIndex = qas.findIndex((qa) => qa.query === queryText);

                // Update the feedback for the specified question and answer
                if (queryIndex !== -1) {
                    qas[queryIndex].feedback = feedback;
                    transaction.update(contractDocRef, { qas });
                }
            });

            console.log(`Feedback (${feedback}) saved successfully.`);
        } catch (error) {
            console.error("Error saving feedback:", error);
        }
    };


    const handleThumbsUp = async () => {
        // Handle the logic when thumbs up is clicked.
        //setShowErrorDialog(true);
        //setErrorMessage('Thanks for the feedback!');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(userID, projectName, query, "thumbsUp");
        setFeedback("thumbsUp");
    };
    const handleThumbsDown = async () => {
        // Handle the logic when thumbs down is clicked.
        //setShowErrorDialog(true);
        //setErrorMessage('We appreciate your feedback. We will work on improving.');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(userID, projectName, query, "thumbsDown");
        setFeedback("thumbsDown");
    };


    return (
        <div className="qaPage">
            
            <div style={{
                backgroundImage: `url(${qdt1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                maxHeight: '100%',
                maxWidth: '100%',
                minHeight: '18vh',
                zIndex: -1,
            }}></div>
            <div style={{ padding: '5vh 5vw 5vh 5vw', backgroundColor: 'var(--backColor)' }}>
                <div style={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.1)' }}>
                    <div className="qaTitle1">
                        <p>Specific knowledge QA</p>
                    </div>
                    <div className="qaTitle2">
                        {!projectName ? (
                            <p>Please select a folder.</p>
                        ) : !csvExists ? (
                            <p><span className="project">{projectName.toUpperCase()}</span> folder. Please synchronise documents.</p>
                        ) : (
                            <p>Ask questions about the <span className="project">{projectName.toUpperCase()}</span> documents.</p>
                        )}
                    </div>
                    <div className="qaPageR">
                        <form onSubmit={handleSubmit} className="QuestionContainer">
                            <div className="QuestionText">
                                <input
                                    type="text"
                                    className="editable-div"
                                    value={query}
                                    list="questions"
                                    onChange={handleInputChange}
                                    placeholder="Select a FAQ or type your own question."
                                    name="query"
                                />
                                <datalist id="questions">
                                    {allQuestions.map((question, index) => (
                                        <option key={index} value={question}>
                                            {question}
                                        </option>
                                    ))}
                                </datalist>
                                <button
                                    type="submit"
                                    className="submitButton1"
                                    disabled={!projectName || !csvExists || !query}
                                >
                                    <FaPaperPlane size={20} />
                                </button>
                            </div>
                        </form>

                        <div className="AnswerText">
                            {isProcessing ? (
                                <div className="blink-box"></div>
                            ) :
                                <>
                                    {question && (
                                        <div className="chat-entry">
                                            <FaUser size={15} className="chat-icon" />
                                            <div>
                                                <div className="label">Question:</div>
                                                <ReactMarkdown skipHtml={true} children={question} className="chat-text" />
                                            </div>
                                        </div>
                                    )}
                                    {prediction && (
                                        <>
                                            <div className="chat-entry">
                                                <FaRobot size={15} className="chat-icon" />
                                                <div>
                                                    <div className="label">Answer:</div>
                                                    <ReactMarkdown skipHtml={true} children={prediction} className="chat-text" />
                                                </div>
                                            </div>
                                            <Feedback userID={userID} projectName={projectName} prediction={prediction} query={query} feedback={feedback} setFeedback={setFeedback} />
                                        </>
                                    )}

                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
        </div>
    );
}

export default QADemo; 