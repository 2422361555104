//Save CSV object in Firebase Storage
//---------------------------------------------------------
//
import { ref, uploadString  } from "firebase/storage";
import { storage } from "../../Firebase";

function saveCSVToStorage(data, projectName, userID) {
    
    const storageRef = ref(storage, `users/${userID}/${projectName}/${projectName}.csv`);

    return uploadString(storageRef, data);
};
export { saveCSVToStorage };


