import { useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import {
    db,
    doc as firebaseDoc,
    setDoc,
    serverTimestamp,
} from '../../Firebase';

export const useGeminiSummary = () => {
    const { userID, projectName } = useContext(AppContext).contextValue;
    const [docSummary, setDocSummary] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const generateSummary = async (pdf) => {
        if (!pdf) {
            console.error('No PDF file selected');
            return;
        }

        setIsLoading(true); 

        const docPath = projectName.split('/').join('_') + "_" + pdf;
        const url = process.env.REACT_APP_GEMINI_SUMMARY_URL;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userID: userID, files_path: projectName, pdf: pdf }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            const answerText = data.candidates[0].content.parts[0].text;
            setDocSummary(answerText);


            const docPathWithoutExtension = docPath.replace('.pdf', '');

            // Saving Summary to Firebase
            const docRef = firebaseDoc(db, "users", userID, "documents", docPathWithoutExtension);
            const doc_data = {
                'docSummary': answerText,
                'docSummaryTime': serverTimestamp(),
            };
            await setDoc(docRef, doc_data, { merge: true }).catch(error => console.error("Error writing document: ", error));

            return answerText;  // Return the summary directly

        } catch (error) {
            console.error('Error:', error);
            setDocSummary('Failed to fetch answer. Please try again.');
            return null;  // Return null if there's an error
        } finally {
            setIsLoading(false);
        }
    };

    return { docSummary, isLoading, generateSummary };
};


