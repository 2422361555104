import Footer from "./components/page/Footer";
import GPTAppDemo from "./GPTAppDemo";
import QPage from "./twintig/QPage";

function App({ user }) {

    const isAuthenticated = !!user;

    return (
        <>
            {isAuthenticated ? ( <GPTAppDemo user={user} />
            ) : (<QPage user={user} />
            )}
            <Footer />
        </>
  );
}

export default App; 
