import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { Navigate } from 'react-router-dom';
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2 } from '../../twintig/ResponsiveComponents';
import {
    db,
    doc,
    getDocs,
    getDoc,
    collection,
    query,
} from '../../Firebase';
import "../../twintig/tw.css";
import '../css/GPTApp.css';
import { addGlobalData } from '../../twintig/globalData';

function formatNumber(number) {
    return number.toLocaleString();
}
function PricingTable({ data, onValueChange }) {
    return (<div className='pricingTable'>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Embedding Price</th>
                            <th>Input Price</th>
                            <th>Output Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.model}</td>
                                <td>
                                    <input
                                        type="number"
                                        value={item.prices.emb}
                                        onChange={e => onValueChange(index, 'emb', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={item.prices.input}
                                        onChange={e => onValueChange(index, 'input', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={item.prices.output}
                                        onChange={e => onValueChange(index, 'output', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    );
}


function AdminDashBoard() {
    // State variable to trigger re-render
    const [refreshKey, setRefreshKey] = useState(0);
    // Function to handle click event in Global data Update
    const handleGlobalDataUpdate = () => {
        addGlobalData()
            .then(() => {
                setRefreshKey(prevKey => prevKey + 1); // This updates the state
            })
            .catch(console.error);
    };

    const { userStatus, userID } = useContext(AppContext).contextValue;
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);

    const [userCount, setUserCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTokensE, setTotalTokensE] = useState(0);
    const [totalTokensI, setTotalTokensI] = useState(0);
    const [totalTokensO, setTotalTokensO] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [pricingData, setPricingData] = useState([]);

    useEffect(() => {
        const fetchUserStats = async () => {
            setIsLoading(true);  // start loading
            const userSnapshot = await getDocs(collection(db, "users"));
            let totalAmountSum = 0;
            let totalTokensESum = 0;
            let totalTokensISum = 0;
            let totalTokensOSum = 0;
            let transactionCount = 0;

            for (const userDoc of userSnapshot.docs) {
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    if (data.totalAmount) {
                        totalAmountSum += data.totalAmount;
                    }
                    if (data.tokensE_Total) {
                        totalTokensESum += data.tokensE_Total;
                    }
                    if (data.tokensI_Total) {
                        totalTokensISum += data.tokensI_Total;
                    }
                    if (data.tokensO_Total) {
                        totalTokensOSum += data.tokensO_Total;
                    }
                    const userID = userDoc.id;
                    const tokenHistorySnapshot = await getDocs(query(collection(db, 'users', userID, "tokenHistory")));

                    if (!tokenHistorySnapshot.empty) {
                        transactionCount += tokenHistorySnapshot.size;
                    }
                }
            }

            setUserCount(userSnapshot.size);
            setTotalAmount(totalAmountSum);
            setTotalTokensE(totalTokensESum);
            setTotalTokensI(totalTokensISum);
            setTotalTokensO(totalTokensOSum);
            setTotalTransactions(transactionCount);

            setIsLoading(false); // done loading
        };

        fetchUserStats();
    }, []);

    useEffect(() => {
        const fetchPricingData = async () => {
            setIsLoading2(true);  // start loading

            const configDoc = await getDoc(doc(db, "globalData", "config"));

            if (configDoc.exists()) {
                const configData = configDoc.data();
                setPricingData(configData.pricing || []);
            }

            setIsLoading2(false);  // done loading
        };

        fetchPricingData();
    }, [refreshKey]);

    function handlePricingValueChange(index, key, value) {
        const updatedPricingData = [...pricingData];
        updatedPricingData[index].prices[key] = parseFloat(value);
        setPricingData(updatedPricingData);
        // Note: At this point, you might also want to send this updated data back to the database.
    }

    const formattedTotalAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(totalAmount);

    if (userStatus !== "admin") {
        return <Navigate to="/qtig" replace />;
    }

    return (
        <div>
            <div className="DashBoardTitle">Administrator Dashboard</div>
            <div style={{
                backgroundColor: 'var(--backColor)',
                margin: '0',
                padding: '0 0 5vh 0',
             }}>
                <ResponsiveContainer>
                    <ResponsiveElement2>
                        {isLoading ? (
                            <div className="blinking-dashboard">Loading...</div>
                        ) : (
                            <div className="DashBoard-text">
                                <p>Number of users: </p> <p className="DashBoard-bold">{formatNumber(userCount)}</p>
                                <p>Total amount: </p> <p className="DashBoard-bold">{formattedTotalAmount}</p>
                                <p>Total transactions: </p> <p className="DashBoard-bold">{formatNumber(totalTransactions)}</p>
                                </div>
                        )}
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        {isLoading ? (
                            <div className="blinking-dashboard">Loading...</div>
                        ) : (
                            <div className="DashBoard-text">
                                <p>Embedding tokens: </p> <p className="DashBoard-bold">{formatNumber(totalTokensE)}</p>
                                <p>Input tokens: </p> <p className="DashBoard-bold">{formatNumber(totalTokensI)}</p>
                                <p>Output tokens: </p> <p className="DashBoard-bold">{formatNumber(totalTokensO)}</p>
                                </div>
                        )}
                    </ResponsiveElement1>
                </ResponsiveContainer>
                {/* Render Pricing Table Here */}
                {isLoading2 ? (
                    <div className="blinking-dashboard">Loading Pricing Data...</div>
                ) : (<div className="DashBoard-priceTable">
                            <PricingTable data={pricingData} onValueChange={handlePricingValueChange} />
                    </div>
                )}
                <div
                    className="DashBoard-global"
                    onClick={handleGlobalDataUpdate}
                    key={refreshKey} 
                >
                    Update Global Data
                </div>
            </div>
        </div>
    );
}

export default AdminDashBoard;