import React from "react";
import { Link } from 'react-router-dom';
import "../css/auth.css";
import "../../twintig/tw.css";

import tw from '../../assets/tw.svg';

function HeaderAuth() {
    return (
        <div className="App-header">  
            <Link exact to="/" title="twintig.ai">
                <img src={tw} alt="twintig" className="Tw-logo" />
            </Link>
        </div>
    );
}
export default HeaderAuth;