import React, { useContext } from "react";
import { Route, Routes } from 'react-router-dom';

import { AppContext} from '../../AppContext';


import QHomeContracts from "../GptDemo/QHomeContracts";
import QHomeDemo from "../GptDemo/QHomeDemo";

import '../css/GPTApp.css';


function QHome() {

    const { userID, userStatus, loading } = useContext(AppContext).contextValue;
    if (loading) {
        return <div></div>;
    }

    return (
            <div>
                <Routes>
                    <Route
                    path="*"
                    element={userStatus === 'demo' ? <QHomeDemo /> : <QHomeContracts userID= {userID}/>}
                    />
                </Routes>
            </div>
    );
}

export default QHome; 