import React from "react";

import "../css/GPTApp.css";
import "../../twintig/tw.css";

function Footer() {
    return (
        <footer className="App-footer">
            <p className="footer_text">&copy; 2024 Twintig srl. All rights reserved.</p>
            <div className="footer-tw-text" style={{ marginRight: '10px'}}>
                <a href="mailto:info@twintig.ai" style={{ color: 'white', textDecoration: 'none' }}>info@twintig.ai</a>
            </div>
        </footer>
    );
}
export default Footer;