import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import "../css/Dialog.css";
import "../css/GPTtables.css";

import AlertDialog from "./AlertDialog";

function SummaryDocModal({ SummaryDocModalVisible, setSummaryDocModalVisible, closeDocSummaryModal, summary, pdf, isLoading }) {

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);


    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    const handleSubmit = () => {
        setSummaryDocModalVisible(false);
    };


    return (
        <>
            <Modal show={SummaryDocModalVisible} onHide={closeDocSummaryModal} className="modalS" scrollable={true} backdrop="static" dialogClassName="modalS-dialog">

                <Modal.Header className="modalHeader" closeButton>
                    <Modal.Title className="modalTitle">
                        {pdf}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="modalSummaryBody chat-entry" >
                    {isLoading ? (
                        <div className="AnswerText blink-box">Your summary is being prepared, please wait...</div> // Your custom loading indicator
                      ) : (
                        <ReactMarkdown skipHtml={true} className="chat-text">{summary}</ReactMarkdown>
                        )
                    }
                </Modal.Body>
                <Modal.Footer className="modalFooter">
                    <Button
                        className="confirmButton"
                        type="submit"
                        onClick={() => handleSubmit()}
                        disabled={isLoading}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
                dialogClass="modalD-dialog a"
            />

        </>
    );
}
export default SummaryDocModal;
