import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
  }, [location.pathname]);

  return null;
}

export default ScrollToTop;
