import React from 'react';
import HeaderTw from "./HeaderTw";
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from './ResponsiveComponents';

import "./tw.css";

import a1 from '../assets/site/ab1.png';
import a2 from '../assets/site/ab2.png';
import tw from '../assets/twintig.svg';



function AboutUs({ user }) {

    return (
        <>
            <HeaderTw user={user} />
            <div className="Tw-Body">
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <ResponsiveImage src={a1} alt="image1" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <p><b>Twintig s.r.l</b></p>
                            <p className="Tw-text5">Limited Liability Company</p>
                            <p className="Tw-text4">Rome Business Registry and tax identification number 15721761003</p>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="AboutLogo">
                            <img className="logo" src={tw} alt="twintig" />
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <div className="Tw-text2 Tw-text3">Twintig is registered in the Register of European Union trade marks.</div>
                    </ResponsiveElement1>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <div className="Tw-text">
                            <p className="Tw-text5">All images in this website have been created with the help of AI and are property of Twintig s.r.l.</p>
                        </div>
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <ResponsiveImage src={a2} alt="image2" />
                    </ResponsiveElement2>
                </ResponsiveContainer>
                
            </div>
        </>
    );
}

export default AboutUs; 