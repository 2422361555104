import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
    auth,
    registerWithEmailAndPassword,
    signInWithGoogle,
} from "../../Firebase";
import AlertDialog from "../utils/AlertDialog";


function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const register = () => {
        if (!name) {
            setShowErrorDialog(true);
            return;
        }
        registerWithEmailAndPassword(name, email, password);
    };

    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
    };

    useEffect(() => {
        if (loading) return;
        if (user) navigate("/qtig");
    }, [user, loading]);
    return (
        <div className="register">
            <div className="form_container1">
                <input
                    type="text"
                    className="register__textBox"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                />
                <input
                    type="text"
                    className="register__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                <input
                    type="password"
                    className="register__textBox"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button className="action_btn" onClick={register}>
                    Register
                </button>
                <button
                    className="action_btn_google"
                    onClick={signInWithGoogle}
                    >
                    Register with Google
                </button>
                <div className='login__text'>
                    Already have an account? <Link to="/login">Login</Link> now.
                </div>
            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Error"
                message="Please enter a valid name."
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />

        </div>
    );
}
export default Register;