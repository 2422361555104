import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import HeaderTw from "./HeaderTw";
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from './ResponsiveComponents';

import "./tw.css";
import p1 from '../assets/qtig/qd1.svg';

function PlaceholderPage({ user }) {
    const navigate = useNavigate();
    const { userStatus } = useContext(AppContext).contextValue;

    useEffect(() => {
        if (userStatus) {
            // Redirect to the home page or another appropriate page
            navigate('/qtig');
        }
    }, [userStatus, navigate]);
    return (
        <>
            <HeaderTw user={user} />
            <div className="Privacy-Body">
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <ResponsiveImage src={p1} alt="image1" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-textPrivacy">
                            <p>Loading user information, please wait...</p>
                            <p>If you are not redirect, please reload the page.</p>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <div className="PrivacyDoc">

                </div>


                
            </div>
        </>
    );
}

export default PlaceholderPage; 