import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderTw from "./twintig/HeaderTw";
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2 } from './twintig/ResponsiveComponents';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ModalEx } from './components/utils/ModalEx';
import RectangleContainer from './components/utils/RectangleContainer';
import RectangleSkyline from './components/utils/RectangleSkyline';
import CookieBanner from './components/utils/CookieBanner';
import AnimatedText from './components/utils/AnimatedText';
import SVGContainer from './components/utils/SVGContainer';
import QtigAnimation from './components/utils/QtigAnimation';

import "./twintig/tw.css";
import tw from './assets/site/twintig.svg';
import w1 from './assets/site/web1.png';
import w1p from './assets/site/web1p.png';
import w3 from './assets/site/web3.svg';
import w4 from './assets/site/web4.svg';
import w5 from './assets/site/web5.png';
import w5p from './assets/site/web5p.png';
import w7 from './assets/site/web7.png';
import w7p from './assets/site/web7p.png';
import w8 from './assets/site/web8.svg';
import t1 from './assets/site/t1.svg';
import t2 from './assets/site/t2.png';
import x from './assets/site/x.svg';

function Tw({ user }) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const textAnimation = {
        hidden: { y: -40, opacity: 0, scale: 1},
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: { duration: 1 },
        },
    };
    const squareAnimation = {
        hidden: {opacity: 0.3},
        visible: {
            opacity: 1,
            transition: { duration: 2 },
        },
    };
    
    return (
        <>
            <HeaderTw user={user}/>
            <div className="Tw-Body">
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <AnimatedText animation={squareAnimation}><RectangleContainer /></AnimatedText>
                        <div className="orange-rect transp News-text">
                            <AnimatedText animation={textAnimation}>Great news!<br />Keep scrolling, it's worth it.</AnimatedText>
                        </div>
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Building Artificial Intelligence in the construction industry.</AnimatedText>
                            <img className="logo" src={tw} alt="twintig" />   
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Managing the high complexity in large projects is a very hard task.</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w1} alt="image1" placeholderSrc={w1p} />
                    </ResponsiveElement1>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w3} alt="image3" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>A new era in construction is coming. AI is here!</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>How can we apply AI in the construction industry?</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w4} alt="image4" />
                    </ResponsiveElement1>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w5} alt="image5" placeholderSrc={w5p} />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Machine Learning and <span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow1}>Deep Learning*</span> can help us to manage large projects.</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Leveraging the power of Large Language Models (<span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} title="See more..." onClick={handleShow}>LLMs</span>).</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <SVGContainer />
                    </ResponsiveElement1>
                </ResponsiveContainer>

                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w7} alt="image7" placeholderSrc={w7p} />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Contracts for large projects are made up of thousands of pages and are very articulate and complex.</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Imagine being able to ask questions about your contracts and get answers or a summary of your documents in a few seconds.</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <LazyLoadImage className="lazy" src={w8} alt="image8" />
                    </ResponsiveElement1>
                </ResponsiveContainer>

                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <AnimatedText animation={squareAnimation}><RectangleSkyline /></AnimatedText>
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <AnimatedText animation={textAnimation}>Just as a city's skyline constantly changes, we're commited to ensuring that human potential remains boundless, with AI lighting the way to these new horizons.</AnimatedText>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <div className="orange-rect">
                    <div className="News-text">
                        <>New Web App! <br />Click below to discover it.</>
                    </div>
                </div>
                <div className="qtig-link">
                    <Link to="/qtig" >
                        <AnimatedText animation={squareAnimation}><QtigAnimation /></AnimatedText>
                    </Link>
                </div>
                <div className="big-footer">
                    <div className="footer-cols">
                        <div>
                            <img src={tw} alt="twintig" className="tw-logo-footer" />
                        </div>
                        <div>
                            <p className="footer-tw-text">&copy; 2024 Twintig srl<br />
                                All rights reserved
                            </p>
                        </div>
                    </div>
                    <div className="footer-cols">
                        <div className="footer-icons">
                            <a className="social-icons" href="https://www.linkedin.com/company/twintig-srl/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} style={{ color: 'var(--backColor)'}} />
                            </a>
                            <a className="social-icons" href="https://www.instagram.com/twintigai" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} style={{ color: 'var(--backColor)' }} />
                            </a>

                            <a className="social-icons" href="https://www.twitter.com/twintigai" target="_blank" rel="noopener noreferrer">
                                <img alt="x" src={x} style={{ color: 'var(--backColor)', height: '28px' }} />
                            </a>
                        </div>
                        <div className="footer-tw-text">
                            <br /><br />
                            <a href="mailto:info@twintig.ai" style={{ color: 'var(--backColor)', textDecoration: 'none' }}>info@twintig.ai</a>
                        </div>


                    </div>
                    <div className="footer-cols">
                        <Link to="/about" className="footer-links-text">
                            About us
                        </Link>
                        <Link to="/privacy" className="footer-links-text">
                            Privacy Policy
                        </Link>
                    </div>

                </div>
                <div className="lines-wrapper">
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    <div className="line line3"></div>
                    <div className="line line4"></div>
                    <div className="line line5"></div>
                    <div className="line line6"></div>
                    <div className="line line7"></div>
                    <div className="line line8"></div>
                    <div className="line line9"></div>
                    <div className="line line10"></div>
                </div>
                <ModalEx
                    show={show}
                    title='LLMs'
                    handleClose={handleClose}
                    imgSrc={t1}
                    explanation='A large language model (LLM) is a computerized language model,
                    embodied by an artificial neural network (pre-)trained on a vast
                    amounts of unlabeled texts containing up to trillions of words.
                    Our platform leverages the capabilities of Google Gemini Pro, one of the most advanced artificial intelligence language models available.
                    This enables us to provide enhanced features and interactions, ensuring an optimal user experience.'
                    confirmLabel='Close'
                />
                <ModalEx
                    show={show1}
                    title='Deep Learning'
                    handleClose={handleClose1}
                    imgSrc={t2}
                    explanation='Deep learning is part of a broader family of machine learning methods,
                    which is based on artificial neural networks with representation learning.
                    The adjective "deep" in deep learning refers to the use of multiple layers in the network.
                    Methods used can be either supervised, semi-supervised or unsupervised.'
                    confirmLabel='Close'
                />

            </ div>

                <CookieBanner />


       </>

  );
}

export default Tw; 
