import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import addFile from '../../assets/addFile.png';
import '../css/GPTApp.css';
import { storage, ref, uploadBytes, getSignedUrlFolderCallable } from '../../Firebase';

const acceptedFileType = { 'application/pdf': ['.pdf'] };

function PdfUpload({ userID, projectName, onFilesUploaded, onStartLoading, onFinishLoading, maxFiles = 1 }) {

     const handleFileUpload = async (userID, projectName, files) => {
        // Create a reference to the PDFs folder in the user's Storage directory
         const pdfsRef = ref(storage, `users/${userID}/${projectName}`);

        // Initialize an array to keep track of successfully uploaded files
        let uploadedFiles = [];

        // Upload each file to the PDFs folder and track the successful uploads
        const uploadTasks = files.map(async (file) => {
            const fileRef = ref(storage, `${pdfsRef.fullPath}/${file.name}`);
            try {
                await uploadBytes(fileRef, file);
                // If upload is successful, add file name to the uploadedFiles array
                uploadedFiles.push(file.name);
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                // Optionally, handle errors for each file here (e.g., add to a separate error list)
            }
        });

        // Wait for all upload tasks to complete
        await Promise.all(uploadTasks);

        // Return the signed URL for the PDFs folder
        return uploadedFiles;
    };

    const onDrop = async (acceptedFiles, onStartLoading, onFinishLoading, onUpdateProjects, fileRejections) => {

        if (!projectName || projectName === '') {
            console.log('Project name is not defined. Skipping file upload.');
            return;
        }
        try {
            if (onStartLoading) onStartLoading();
            const uploadedFiles = await handleFileUpload(userID, projectName, acceptedFiles);
           
            if (onFilesUploaded) onFilesUploaded(uploadedFiles);

        } catch (error) {
            console.error(error);
        } finally {
            if (onFinishLoading) onFinishLoading();
        }
    };

    return (
        <div className='dropzone'>
            <Dropzone
                onDrop={(acceptedFiles) => onDrop(acceptedFiles, onStartLoading, onFinishLoading)}
                accept={acceptedFileType}
                maxFiles={maxFiles}  // Set the maximum number of files to 1
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div
                        className="inp"
                        {...getRootProps({ onClick: projectName ? undefined : (e) => e.stopPropagation() })}
                    >
                        <input {...getInputProps()} />
                        <p>
                            {projectName
                                ? "Drag and drop a file here, or click to select a file."
                                : "Disabled (select a contract first)"}
                        </p>

                        <img
                            style={{ height: '45px', float: 'inherit', margin: '0px 0px 4px 0px' }}
                            src={addFile} alt="AddFile"
                        />
                    </div>
                )}
            </Dropzone>


        </div>
    );
}

export default PdfUpload;


