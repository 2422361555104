import React from "react";
import { FaHome, FaRegCommentDots, FaDochub, FaFileAlt } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import '../css/GPTMenu.css';

function Menu() {
    const { pathname } = useLocation();
    return (
        <nav className="menuline">
            <NavLink className={pathname === '/qtig' ? "menuActive" : "menubars"} to="/qtig"><FaHome size={20} style={{ color: 'white' }} /><br/>Home</NavLink>
            <NavLink className={pathname === '/qtig/docs' ? "menuActive" : "menubars"} to="docs"><FaDochub size={20} style={{ color: 'white' }} /><br />Docs</NavLink>
            <NavLink className={pathname === '/qtig/qa' ? "menuActive" : "menubars"} to="qa"><FaRegCommentDots  size={20} style={{ color: 'white' }} /><br />Q&A</NavLink>
            <NavLink className={pathname === '/qtig/analysis' ? "menuActive" : "menubars"} to="analysis"><FaFileAlt size={20} style={{ color: 'white' }} /><br />Analysis</NavLink>
        </nav>
    );
}

export default Menu;
