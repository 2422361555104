import React from "react";
import { Link } from 'react-router-dom';
import Logout from '../service/Logout';
import "../../twintig/tw.css";
import qtig from '../../assets/qtig.svg';

function Header(props) {
    return (
        <header className="App-header">
            <Link to="/" title="twintig.ai">
                <img src={qtig} alt="qtig" className="App-logo" />
            </Link>
            <p className="header_text">Contracts Q&A</p>
            <Logout user_mail={props.user_mail} />
        </header> 
    );
}
export default Header;