import React, { useState, useEffect } from 'react';
import '../css/square.css';

const colorList = ['#000000', '#808080', '#F06E1E', '#FFFFFF', '#C0C0C0', '#F5F5F5', '#F2E9D9', '#B7B6B1', '#FDECD3', '#FEF0D8', '#F06E1E'];

function RectangleContainer() {
    const [rectangles, setRectangles] = useState([]);
    const [completed, setCompleted] = useState(false);
    const maxRectangles = 8;

    useEffect(() => {
        if (!completed) {
            let timeout;
            const addRectangleInterval = () => {
                if (rectangles.length < maxRectangles) {
                    addRectangle();
                    timeout = setTimeout(addRectangleInterval, 200 + rectangles.length * 20);
                } else {
                    setCompleted(true);
                }
            };
            addRectangleInterval();

            return () => clearTimeout(timeout);
        }
    }, [completed, rectangles.length]);

    const addRectangle = () => {
        const width = Math.random() * (300 - 30) + 30;
        const height = Math.random() * (300 - 30) + 30;
        const top = Math.random() * (350 - height); // Assuming container height as 350px
        const left = Math.random() * (300 - width); 
        const color = getRandomColorFromList();
        const newRectangle = { width, height, top, left, color, id: Math.random() };

        setRectangles(prev => [...prev, newRectangle]);
    };

    const handleMouseEnter = () => {
        if (completed) {
            setRectangles([]);
            setCompleted(false);
        }
    };

    return (
        <div className="rect-container">
            <div onMouseEnter={handleMouseEnter} className="rect-content">
                {rectangles.map(rect => (
                    <div key={rect.id} style={{
                        position: 'absolute',
                        backgroundColor: rect.color,
                        width: `${rect.width}px`,
                        height: `${rect.height}px`,
                        top: `${rect.top}px`,
                        left: `${rect.left}%`,
                        opacity: 0,
                        animation: 'fadeInScaleUp 1.5s forwards',
                        boxShadow: '10px 10px 10px 10px rgba(0,0,0,0.2)',
                    }} />
                ))}
            </div>
        </div>
    );
}

function getRandomColorFromList() {
    const index = Math.floor(Math.random() * colorList.length);
    return colorList[index];
}

export default RectangleContainer;


