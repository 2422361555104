import React from 'react';
import HeaderTw from "./HeaderTw";
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from './ResponsiveComponents';

import "./tw.css";
import p1 from '../assets/site/p1.svg';

function Privacy({ user }) {

    return (
        <>
            <HeaderTw user={user} />
            <div className="Privacy-Body">
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <ResponsiveImage src={p1} alt="image1" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-textPrivacy">
                            <p>This Privacy Police explains Twintig's purposes and legal basis for the information we may collect about data subjects, sources of information and how the information is processed.</p>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <div className="PrivacyDoc">
                    <h3>Who we are</h3>



                    <ul className="is-style-tw-no-bullet"><li>TWINTIG S.R.L.</li><li>https://twintig.ai</li><li>info@twintig.ai</li></ul>



                    <h3>Purpose and legal basis</h3>



                    <p>The processing of personal data is based on our legitimate interest to freely conduct business and maintain our Service in accordance with European Union law and national laws.</p>



                    <p>The legal basis for collecting user data is Twintig's legitimate interest to maintain the Service, as well as maintenance, development and to improve the Service. User data will not be used for any other purpose. </p>



                    <p>Personal data in the Twintig database record may be processed for the following purposes:</p>



                    <ul><li>To offer functionality and support and to carry out the Service;</li><li>To update and improve the Service;</li><li>Statistical analyses on Companies based on depersonalized data;</li><li>Fulfill the obligations based on law and orders of the authorities.</li></ul>



                    <h3>Sources of Personal Data</h3>



                    <p>Twintig collects personal data primarily from open and public data of legal entities, which is available in the public domain or has been made available to the public by the legal entities. All of the data we collect is within the public domain, such as news, public records, social media posts, company websites, press releases or otherwise disclosed. We merely use intelligent systems to filter this information and provide this to our customers through our Services. All personal data is updated when the source of information is updated.</p>



                    <h3>Recipients of Personal Data</h3>



                    <p>Users of the Service have access to some or all of the personal data in relation to the legal entities they search for. All personal data that is available to the users and customers of our Service is freely accessible in the public domain, or otherwise available to the public in the same manner and similar format as is provided in our Service.<br />Personal Data may be disclosed to public authorities if this is necessary to fulfill a legal obligation.</p>



                    <h3>Data Subjects</h3>



                    <p>The data in the Service mainly concerns companies. A limited amount of data may be linked to a person based on their public role in a company that is publicly disclosed, such as CEO or board members. The processing is limited to persons holding a role in public life and is not extended to beyond what the data subjects could reasonably expect.<br />Twintig also collects personal data from users of the Service in order to update and improve the Service, to offer support and functionality, and to carry out the Service.</p>



                    <h3>Personal Data in the Record</h3>



                    <p>The Record may contain the following personal data in connection with the legal entity:</p>



                    <ul><li>Name;</li><li>Title;</li><li>Position;</li><li>Country;</li><li>Contact information (phone and/or email address).</li></ul>



                    <p>Twintig may collect the following personal data from users of the Service:</p>



                    <ul><li>IP address;</li><li>Emai adress;</li><li>Usage.</li></ul>



                    <h3>Third Party Providers </h3>



                    <p>Twintig is currently using third parties in providing the Service, provided that such third parties ensure a level of privacy protection as required by applicable regulations to offer the services. These services may include providing cloud infrastructure and account administration. A copy of the list of third parties used by Twintig may be available upon request by emailing at info@twintig.ai.</p>



                    <h3>Retention of Personal Data</h3>



                    <p>Personal data will be stored in the database only as long as and only to the extent that is necessary to provide timely and accurate information to customer decision-makers as part of providing the Service. The database is automatically updated on a regular basis, and personal data will not be stored beyond a reasonable time after it has been removed from the original source.</p>



                    <h3>Rights of the Data Subject</h3>



                    <p>The data subjects have certain rights and this section of the database privacy notice is intended<br />to provide information on these rights. Under the Data Protection legislation, data subjects have the following rights with regards to their personal information:</p>



                    <ul><li>the right to obtain from Twintig confirmation as to whether or not personal data concerning him or her are being processed, and, where that is the case, access to the personal data;</li><li>the right to obtain from Twintig, without undue delay, the rectification of inaccurate personal data concerning him or her. Twintig will also automatically update the personal data gathered about when the personal data is changed in the original data source;</li><li>the right to request erasure of personal data in the Database, if the legal basis for processing of personal data has ceased;</li><li>the right to complain to the supervisory authority.</li></ul>



                    <h3>Embedded content from other websites</h3>



                    <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>



                    <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>



                    <h3>Changes to this Privacy Policy</h3>



                    <p>We evaluate this privacy policy periodically in light of changing business practices, technology and legal requirements. As a result, it is updated from time to time.</p>



                    <p>Last modified: 27 September 2020</p>
                </div>


                
            </div>
        </>
    );
}

export default Privacy; 