import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../css/Tips.css';

const ModalEx = ({ show, handleClose, title, imgSrc, explanation, confirmLabel }) => {
    return (
        <Modal className="modalE" show={show} onHide={handleClose} backdrop="static" dialogClassName="modalE-dialog">
            <Modal.Header className="modalE-header">
                <Modal.Title className="modalE-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalE-body clearfix">
                <img className="imageD" src={imgSrc} alt="info" />
                {explanation}
            </Modal.Body>
            <Modal.Footer className="modalE-footer">
                <Button className="confirmButtonE" variant="dark" onClick={handleClose}>
                    {confirmLabel}
                </Button>
            </Modal.Footer>

        </Modal>
    );
}
const ModalExW = ({ show, handleClose, title, explanation, confirmLabel }) => {
    return (
        <Modal className="modalE" show={show} onHide={handleClose} backdrop="static" dialogClassName="modalE-dialog">
            <Modal.Header className="modalE-header">
                <Modal.Title className="modalE-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalE-body clearfix">
                {explanation}
            </Modal.Body>
            <Modal.Footer className="modalE-footer">
                <Button className="confirmButtonE" variant="dark" onClick={handleClose}>
                    {confirmLabel}
                </Button>
            </Modal.Footer>

        </Modal>
    );
}

export { ModalEx, ModalExW };
