import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { useMediaQuery } from 'react-responsive';
import { FaSearchMinus, FaSearchPlus, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "../css/pdf.css";
import 'react-pdf/dist/Page/TextLayer.css'; // Important !!!!

// Ensure pdfjs uses workers from CDN (important for performance)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewerModal({ viewPdfModalVisible, closePdfModal, selectedPdf }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pdfViewScale, setPdfViewScale] = useState(1);

    const isMobile = useMediaQuery({ maxWidth: 712 });

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const scrollPosition = scrollTop / (scrollHeight - clientHeight / 2);
        const estimatedPage = Math.max(Math.ceil(scrollPosition * numPages), 1);
        setCurrentPageNumber(estimatedPage);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                console.log('Resized element:', entry.target, 'New dimensions:', entry.contentRect.width, entry.contentRect.height);
            }
        });

        const modalElement = document.querySelector('.modalE-dialog'); // Adjust selector as needed
        if (modalElement) {
            resizeObserver.observe(modalElement);
        }

        return () => {
            if (modalElement) {
                resizeObserver.unobserve(modalElement);
            }
        };
    }, []);


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page when new document is loaded
        setCurrentPageNumber(1);
    }

    function goToPrevPage() {
        setPageNumber(pageNumber - 1);
    }

    function goToNextPage() {
        setPageNumber(pageNumber + 1);
    }


    function handleDecreaseScale() {
        setPdfViewScale(prevScale => Math.max(prevScale - 0.1, 0.6));
    }

    function handleIncreaseScale() {
        setPdfViewScale(prevScale => Math.min(prevScale + 0.1, 1.8));
    }

    // Handle changing the page number from the input
    const handlePageChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 1 && value <= numPages) {
            setPageNumber(value); // Update the page number if it's within the valid range
        }
    };

    // Handle onBlur to reset invalid input to the current page
    const handleBlur = () => {
        setPageNumber(pageNumber); // This ensures the input shows the correct current page even if an invalid number was entered
    };



    return (
        <Modal show={viewPdfModalVisible} onHide={closePdfModal} scrollable={true} className="modalPDF" backdrop="static" dialogClassName="modalPDF-dialog">

            <Modal.Header className="modalPDF-header">
                <Modal.Title className="modalPDF-title">
                    <span className="modalPDF-title1">PDF Viewer </span>
                    <span className="modalPDF-title2">{selectedPdf.pdfName}</span>
                    <span className="modalPDF-title3">&#x1F50E;&#xFE0E; CTRL-F</span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalPDF-body" {...(!isMobile && { onScroll: handleScroll })} >
                <Document
                    file={selectedPdf.pdfUrl}
                    loading={<div className="blink-box-PDF">Loading...</div>}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="modalPDF-document"
                    {...(!isMobile && { onScroll: handleScroll })} // Apply onScroll only if isMobile is false
                >
                    {isMobile ?
                        <Page
                            className="modalPDF-page"
                            pageNumber={pageNumber}
                            scale={pdfViewScale}
                            loading={<div className="blink-box-PDF">Loading...</div>}
                        />
                        :
                        Array.from(new Array(numPages), (el, index) => (
                            <Page
                                className="modalPDF-page"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={pdfViewScale}
                                loading={<div className="blink-box-PDF">Loading...</div>}
                            />
                        ))
                    }
                </Document>

            </Modal.Body>
            <Modal.Footer className="modalPDF-footer">

                {isMobile && (
                    <div className="pdf-navigation">
                        <Button className="pdf-pageButton" onClick={goToPrevPage} disabled={pageNumber <= 1}><FaAngleLeft /></Button>

                        <input
                            type="number"
                            className="pdf-pageInput"
                            value={pageNumber}
                            onChange={handlePageChange}
                            onBlur={handleBlur}
                            min="1"
                            max={numPages}
                        />

                        <span className="pdfzoomInput"> of {numPages}</span>
                        <Button className="pdf-pageButton" onClick={goToNextPage} disabled={pageNumber >= numPages}><FaAngleRight /></Button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pdf-navigation">
                        <span className="pdfzoomInput1">{currentPageNumber} of {numPages}</span>
                    </div>
                )}

                <div className="zoomButtonPDF" >
                    <span
                        className={`sliderText ${pdfViewScale <= 0.6 ? 'disabledIcon' : ''}`}
                        onClick={handleDecreaseScale}
                    >
                        <FaSearchMinus />
                    </span>
                    <span className="pdfzoomInput">
                        {Math.round(pdfViewScale * 100)}%
                    </span>
                    <span
                        className={`sliderText ${pdfViewScale >= 1.8 ? 'disabledIcon' : ''}`}
                        onClick={handleIncreaseScale}
                    >
                        <FaSearchPlus />
                    </span>

                </div>
                <Button className="confirmButtonPDF" variant="dark" onClick={closePdfModal}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
export default PdfViewerModal;
