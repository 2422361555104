import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AppContextProvider, AppContext} from './AppContext';

import Header from "./components/page/Header";
import Menu from "./components/page/GPTMenu";

import QHome from "./components/GptDemo/QHome";
import QPdf from "./components/GptDemo/QPdf";
import QA from "./components/GptDemo/QA";
import QSummary from "./components/GptDemo/QSummary";

import AdminDashBoard from './components/service/AdminDashBoard';

import './components/css/GPTApp.css';


function GPTAppDemo({ user }) {

    const { userStatus } = useContext(AppContext).contextValue;

    if (!userStatus) {
        // Assuming null or undefined userStatus means it's not yet available
        return <Navigate to="/placeholder" replace/>;
    }

    return (
        <>
            <Header user_mail={user ?.email} />
            <Menu />
            <div>
                <Routes>
                    <Route path="/*" element={<QHome />} />
                    <Route path="docs/*" element={<QPdf />} />
                    <Route path="qa/*" element={<QA />} />
                    <Route path="analysis/*" element={<QSummary />} />
                    {/* Admin routes */}
                    <Route path="/admin/*" element={<AdminDashBoard />} />
                </Routes>
            </div>
        </>
    );
}

export default GPTAppDemo; 


