import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../css/Dialog.css';


function ConfirmationDialog({ show, title, message, onConfirm, onCancel, confirmLabel, cancelLabel }) {
    return (
        <Modal className="modalD" show={show} onHide={onCancel} dialogClassName="modalD-dialog">
            <Modal.Header className="modalHeader" closeButton>
                <Modal.Title className="modalTitle">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">{message}</Modal.Body>
            <Modal.Footer className="modalFooter">
                <Button className="cancelButton" variant="secondary" onClick={onCancel}>
                    {cancelLabel}
                </Button>
                <Button className="confirmButton" variant="dark" onClick={onConfirm}>
                    {confirmLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationDialog;