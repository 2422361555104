import React, { useState, useEffect } from 'react';
import {
    getSignedUrlFolderCallable,
    storage,
    ref,
    listAll,
    getDownloadURL,
    doc,
    db,
    getDoc,
    setDoc,
    serverTimestamp,
} from '../../Firebase';
import { Modal, Button } from 'react-bootstrap';
import "../css/Dialog.css";
import AlertDialog from "./AlertDialog";


function PdfSyncModal({ PdfSyncModalVisible, closePdfSyncModal, userID, projectName }) {

    const [directoryName, setDirectoryName] = useState('');
    const [directoryNameSync, setDirectoryNameSync] = useState('');

    const [prediction, setPrediction] = useState('');
    const [isSync, setIsSync] = useState(false);
    const [isReading, setIsReading] = useState(false);
    const [apiMessage, setApiMessage] = useState('');

    const [buttonText, setbuttonText] = useState('Cancel');


    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    const fetchFilesData = async (projectName) => {
        setIsReading(true);
        try {
            // Generate a signed URL for each PDF in the folder


            const urlResponse = await getSignedUrlFolderCallable({
                folderPath: `users/${userID}/${projectName}`,
            });

            const urlResponseFiltered = urlResponse.data.urls.filter(fileObj => fileObj.fileName.endsWith('.pdf'));
            setDirectoryName(urlResponseFiltered);

            // Fetch NO-Sync files from Firestore database
            const syncedFiles = []; 
            for (const pdfFile of urlResponseFiltered) {
                const docRefName = `${pdfFile.fileName.replace(/\.pdf$/, '').split('/').slice(2).join('_')}`;
                const docRef = doc(db, 'users', userID, 'documents', docRefName);
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists()) {
                    syncedFiles.push(pdfFile);
                }
            }
            setDirectoryNameSync(syncedFiles); 
            
        } catch (error) {
            console.error("Error fetching files data:", error);
            setErrorMessage("Error fetching documents: " + error.message);
            closePdfSyncModal(true);
            setShowErrorDialog(true);
        } finally {
            setIsReading(false);
        }
    };


    useEffect(() => {
       fetchFilesData(projectName);
    }, [PdfSyncModalVisible]);
   
    const handleSubmit = async (event) => {
        if (!directoryName || !directoryNameSync) {
            alert("Please fill in all required fields");
            return;
        }
        event.preventDefault();

        setIsSync(true);
        const url = process.env.REACT_APP_PDF1QTIG;
        const data = {
            directory: directoryNameSync, // Pass the list of file URLs
            user: userID,
            project: projectName.split('/')[0],
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                const text = await response.text();
                console.error('Unexpected response:', text);
                setIsSync(false);
                setErrorMessage("Error in sync documents: " + text);
                throw new Error('Server response was not ok');
            }
            const result = await response.json();

            const e_tokens = result.reduce((sum, item) => sum + item.tokens, 0);

            const sync_files = result.files;
            const returnMessage = `Number of tokens processed: ${e_tokens}`;

            setPrediction(sync_files);
            setApiMessage(returnMessage);

            /** Writting to Firestore
            for (const pdfSync of result) {
                const docRefName = `${pdfSync.files.replace(/\.pdf$/, '').split('/').join('_')}`;
                const docRef = doc(db, 'users', userID, 'documents', docRefName);
                const documentData = {
                    createdAt: serverTimestamp(),
                    tokens: pdfSync.tokens,
                };
                try {
                    // Define the data you want to write - in this case, we're just writing the pdfFile object
                    await setDoc(docRef, documentData);
                    console.log(`Document written with ID: ${docRefName}`);
                } catch (e) {
                    console.error("Error adding document: ", e);
                }
            }
            **/
            setIsSync(false);
        } catch (error) {
            console.error('API error:', error);
            setErrorMessage("Error in sync documents: " + error.message);
            setShowErrorDialog(true);
            setIsSync(false);
        } finally {
            setIsSync(false);
            setbuttonText('Done');
        }
    };

    return (
       <>
        <Modal show={PdfSyncModalVisible} onHide={closePdfSyncModal} className="modalD" backdrop="static" dialogClassName="modalD-dialog">

            <Modal.Header className="modalHeader">
                <Modal.Title className="modalTitle">
                    <span>PDF Synchronise </span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalBody"  >
                <div className="pdfUploadTitle">
                    <p>
                        {projectName
                            ? <p>Synchronise file(s) in <span className="project">{projectName.toUpperCase()}</span> folder</p>
                            : "Please select a Folder"}
                    </p>
                    <div style={{ height: '10vh', display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        {isReading && (
                            <div>
                                <p className="pdfList">
                                    Reading PDF files status
                                </p>
                                <div className="loading-spinner-container1">
                                    <div className="loading-spinner1"></div>
                                </div>
                            </div>
                        )}


                        {directoryNameSync && !isReading
                            ? (<div className="pdfList">
                                <p> <b>{directoryName.length}</b> file(s) in the folder.</p>
                                <p> <b>{directoryNameSync.length}</b> file(s) to be sync.</p>
                            </div>)
                            : ""}
                    </div>
                    <div style={{ height: '10vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {isSync ? (
                            <div className="loading-spinner-container1">
                                <div className="loading-spinner1"></div>
                            </div>
                        ) : (
                            apiMessage ? (
                                <p className="pdfList">{apiMessage}</p>
                             ) : (!isReading && (directoryNameSync.length>0 ? (
                                <p className="pdfList">
                                    The sync process can take a few minutes. <br />
                                    Do you want to synchronise now?
                                </p>) : (
                                <p className="pdfList">
                                    All files in the folder are synchronised!
                                </p>
                                )
                            ))
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalFooter">
                <Button
                    className="cancelButton"
                    variant="dark"
                    onClick={closePdfSyncModal}
                >
                    {buttonText}
                </Button>
                <Button
                    className="confirmButton"
                    type="submit"
                        disabled={isSync || !directoryName || prediction || !directoryNameSync || directoryNameSync.length===0}
                    onClick={handleSubmit}
                >
                    Sync PDFs
                </Button>


            </Modal.Footer>

        </Modal>
        <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
                dialogClass="modalD-dialog a"
        />
    </>
    );
}
export default PdfSyncModal;
