import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../css/square.css';

const colorList = ['#808080', '#F06E1E', '#FFFFFF', '#C0C0C0', '#7D5C4F', '#85736C', '#F5F5F5', '#F2E9D9', '#B7B6B1', '#FDECD3', '#FEF0D8', '#F06E1E'];
const letters = "LLLLLllomOAAa".split("");

// SVG container dimensions
const svgWidth = 100;
const svgHeight = svgWidth;

// Grid cell size
const cellSize = svgWidth / 3; // Adjust based on your preference

// Calculate the number of cells
const cellsX = Math.floor(svgWidth / cellSize);
const cellsY = Math.floor(svgHeight / cellSize);

function SVGContainer() {
    const [shapes, setShapes] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [occupiedCells, setOccupiedCells] = useState(Array(cellsY).fill().map(() => Array(cellsX).fill(false)));
    const maxShapes = 9;

    useEffect(() => {
        if (!completed) {
            let timeout;
            const addShapeInterval = () => {
                if (shapes.length < maxShapes) {
                    addShape();
                    timeout = setTimeout(addShapeInterval, 200 + shapes.length * 20);
                } else {
                    setCompleted(true);
                }
            };
            addShapeInterval();
            return () => clearTimeout(timeout);
        }
    }, [completed, shapes.length]);

    const addShape = () => {
        // Find an unoccupied cell
        let cellX, cellY;
        let found = false;
        for (let i = 0; i < cellsX * cellsY; i++) {
            cellX = Math.floor(Math.random() * cellsX);
            cellY = Math.floor(Math.random() * cellsY);
            if (!occupiedCells[cellY][cellX]) {
                found = true;
                break;
            }
        }

        if (!found) {
            return; // Exit if no unoccupied cell is found
        }

        // Mark the cell as occupied
        const newOccupiedCells = [...occupiedCells];
        newOccupiedCells[cellY][cellX] = true;
        setOccupiedCells(newOccupiedCells);

        const shapeTypes = ['text', 'text1', 'circle', 'text', 'rect1'];
        const shapeType = shapeTypes[Math.floor(Math.random() * shapeTypes.length)];
        const color = getRandomColorFromList();
        const letter = letters[Math.floor(Math.random() * letters.length)];
        const fontSize = Math.random() * (120) + 20;
        const strokeL = Math.random() * 10;
        const fontW = Math.random() * 10 * 100 + 50;
        const width = Math.random() * 0 + 0;
        const height = Math.random() * 0 + 0;

        // Adjust shape positioning to the selected grid cell
        const cx = cellX * cellSize + cellSize / 2;
        const cy = cellY * cellSize + cellSize / 2;

        const newShape = { shapeType, color, cx, cy, letter, fontSize, strokeL, fontW, width, height, id: Math.random() };
        setShapes(prev => [...prev, newShape]);
    };

    const handleMouseEnter = () => {
        if (completed) {
            setShapes([]);
            setOccupiedCells(Array(cellsY).fill().map(() => Array(cellsX).fill(false))); // Reset grid
            setCompleted(false);
        }
    };

    return (
        <div className="svg-container">
            <svg onMouseEnter={handleMouseEnter} className="svg-content" viewBox="0 0 100 100">
                {shapes.map(shape => (
                    // Render shapes based on their type
                    <Shape key={shape.id} shape={shape} />
                ))}
            </svg>
        </div>
    );
}

function Shape({ shape }) {
    switch (shape.shapeType) {
        case 'text':
            return (
                <motion.text
                    x={shape.cx}
                    y={shape.cy}
                    fill={shape.color}
                    fontSize={shape.fontSize}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fontWeight={shape.fontW}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.2 }}
                >
                    {shape.letter}
                </motion.text>
            );
        case 'text1':
            return (
                <motion.text
                    x={shape.cx}
                    y={shape.cy}
                    fill={shape.color}
                    fontSize={shape.fontSize / 2}
                    textAnchor="middle"
                    dominantBaseline="hanging"
                    fontWeight={shape.fontW}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.2 }}
                >
                    {shape.letter}
                </motion.text>
            );
        case 'circle':
            return (
                <motion.circle
                    cx={shape.cx}
                    cy={shape.cy}
                    r={shape.width / 2}
                    fill={shape.color}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                />
            );
        case 'rect':
            return (
                <motion.rect
                    x={shape.cx - shape.width / 2}
                    y={shape.cy - shape.height / 2}
                    width={shape.width}
                    height={shape.height}
                    fill={shape.color}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                />
            );
        default:
            return null; // If the shape type is unknown, don't render anything
    }
}

function getRandomColorFromList() {
    const index = Math.floor(Math.random() * colorList.length);
    return colorList[index];
}

export default SVGContainer;
