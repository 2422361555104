import styled from 'styled-components';

const ResponsiveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 5vh auto;
  max-width: 80vw;

  @media (max-width: 50vw), (orientation: portrait) {
    max-width: 80vw;
    display: flex;
    flex-direction: column;

  }
`;

const ResponsiveContainer1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 5vh 0;
  max-width: 100vw;

  @media (max-width: 50vw), (orientation: portrait) {
    max-width: 100vw;
    display: flex;
    flex-direction: column;

  }
`;

const ResponsiveElement1 = styled.div`
  flex: 0 0 auto;
  width: 50%;

  @media (max-width: 50vw), (orientation: portrait) {
    width: 100%;
    order: 2;
  }
`;

const ResponsiveElement2 = styled.div`
  flex: 0 0 auto;
  width: 50%;

  @media (max-width: 50vw), (orientation: portrait) {
    width: 100%;
    order: 1;
  }
`;


const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;


export { ResponsiveContainer, ResponsiveContainer1, ResponsiveElement1, ResponsiveElement2, ResponsiveImage };
