import React, { useState, useEffect, useContext } from "react";

import { ResponsiveContainer, ResponsiveContainer1, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from '../../twintig/ResponsiveComponents';
import { ModalEx, ModalExW } from '../utils/ModalEx';
import UsageBar from '../utils/UsageBar';
import { AppContext } from '../../AppContext'

import ReactMarkdown from 'react-markdown';
import {
    db,
    doc,
    getDoc,
} from '../../Firebase';

import "../../twintig/tw.css";
import '../css/GPTApp.css';

import qtig from '../../assets/qtig.svg';
import t1 from '../../assets/qtig/t1.svg';
import t2 from '../../assets/qtig/t2.svg';

import w3 from '../../assets/qtig/qd1.svg';
import d5 from '../../assets/qtig/qd2.svg';
import d6 from '../../assets/qtig/qd3.svg';
import d4 from '../../assets/qtig/qd4.svg';

import qdt from '../../assets/qtig/qdt.png';


function QHomeDemo() {

    const { userID, projectName, setProjectName, csvExists, getCsvFile, userStatus } = useContext(AppContext).contextValue;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [disclaimerContent, setDisclaimerContent] = useState("");

    useEffect(() => {
        setProjectName("demo");
    }, []); // empty dependency array, runs once on mount

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'globalData', 'disclaimer'); // Fixed a typo from 'diclaimer' to 'disclaimer'
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setDisclaimerContent(data.content);
                } else {
                    console.log("Document does not exist!");
                }
            } catch (error) {
                console.log("Error fetching standard questions: ", error);
            }
        };

        fetchData();
    }, []);


    return (

        <div style={{
            backgroundColor: 'var(--backColor)',
            margin: '0',
            padding: '0',
        }}>

            <ResponsiveContainer1>
                <ResponsiveElement2>
                    <div className="Tw-text Tw-text1" style={{ backgroundColor: '#020202' }}>
                        <p>Welcome to <img src={qtig} alt="qtig" className="qtigInLine" />!</p>
                    </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                    <ResponsiveImage src={qdt} alt="image1" />
                </ResponsiveElement1>
            </ResponsiveContainer1>
            <ResponsiveContainer>
                <ResponsiveElement2>
                    <div className="Tw-text"><p style={{ color: '#202020' }}>This is a trial version (<span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow2}>disclaimer</span>).</p>
                        <p style={{ color: '#202020' }}>You will work in a <span style={{ color: "#F06E1E", fontWeight: "600" }}>{projectName.toUpperCase()}</span> contract.</p>
                        <p style={{ color: '#202020' }}>The full version will allow you to work with multi-contract environment, as well as interact with QTIG in different ways.</p>
                     </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                    <ResponsiveImage src={w3} alt="image2" />
                </ResponsiveElement1>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement1>
                    <ResponsiveImage src={d5} alt="image1" />
                </ResponsiveElement1>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>Upload your PDF <span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow}>document</span>. It is important that the pdf file has been passed OCR first. Depending on the quality of the document the app will work better.</p>
                    </div>
                </ResponsiveElement2>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>When the upload is finished the file will be <span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow1}>processed</span> and saved in the cloud. The process can take a few minutes depending on the size of the document.</p>
                    </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                    <ResponsiveImage src={d6} alt="image2" />
                </ResponsiveElement1>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement1>
                    <ResponsiveImage src={d4} alt="image1" />
                </ResponsiveElement1>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>Now, you can ask qtig questions about your document!
                            <br /><span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow3}>Check your usage</span>
                        </p>
                    </div>
                </ResponsiveElement2>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement2>
                </ResponsiveElement2>
                <ResponsiveElement1>
                </ResponsiveElement1>
            </ResponsiveContainer>
            <ModalEx
                show={show}
                title='Pdf Document'
                handleClose={handleClose}
                imgSrc={t1}
                explanation="Please note that QTIG is specifically designed for construction contracts and related documents.
                If you upload a document that isn' t a contract, it will undergo validation, and if deemed irrelevant, will be deleted.
                Also, keep in mind that this trial version allows the upload of only one PDF file at a time.

                All files you upload, the processed documents, as well as the questions and answers generated within the app, are securely stored on GOOGLE CLOUD and FIREBASE - renowned platforms known for their rigorous security protocols.
                If you choose to delete your uploaded PDF, all related processed information will also be removed."
                confirmLabel='Close'
            />
            <ModalEx
                show={show1}
                title='Document process'
                handleClose={handleClose1}
                imgSrc={t2}
                explanation='The uploaded document needs to be processed in order to be understable to the machine.
                This process is called embedding.
                Word Embeddings in Natural Language Processing is a technique where individual words are represented as real-valued vectors
                in a lower-dimensional space and captures inter-word semantics.
                '
                confirmLabel='Close'
            />
            <ModalExW
                show={show2}
                title='Disclaimer for trial version'
                handleClose={handleClose2}
                explanation={<ReactMarkdown>{disclaimerContent}</ReactMarkdown>}
                confirmLabel='Agree'
            />
            <ModalExW
                show={show3}
                title='Trial version usage'
                handleClose={handleClose3}
                explanation={<UsageBar userID={userID} />}
                confirmLabel='Got it!'
            />
      </div>
    );
}

export default QHomeDemo; 