import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // If the 'hideCookieBanner' key doesn't exist in local storage, show the banner
        if (!localStorage.getItem('hideCookieBanner')) {
            setShowBanner(true);
        }
    }, []);

    const closeBanner = () => {
        localStorage.setItem('hideCookieBanner', 'true');
        setShowBanner(false);
    }

    if (!showBanner) {
        return null;
    }

    return (
        <div className="cookie-banner">
            <p>TWINTIG may serve cookies to analyse traffic to this site.
                Information about your use of this site is shared with TWINTIG for that purpose.
            </p>
            <div>
                <button onClick={closeBanner}>OK, GOT IT</button>
                <Link to="/privacy"><button>SEE DETAILS</button></Link>
            </div>
        </div>
    );
}

export default CookieBanner;
