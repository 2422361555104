import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext'

import {
    ref,
    storage,
    getSignedUrlCallable,
    listAll,
    db,
    setDoc,
    doc,
    getDoc,
    collection,
    runTransaction,
    serverTimestamp,
} from '../../Firebase';

import AlertDialog from "../utils/AlertDialog";

import SummaryTable from './SummaryTable';
import { FaThumbsUp, FaThumbsDown, FaCopy } from 'react-icons/fa';

import '../css/GPTMenu.css';
import '../css/GPTApp.css';

import qdt1 from '../../assets/qtig/qdt1.png';


function SummaryDemo() {
    const { userID, projectName, csvExists  } = useContext(AppContext).contextValue;
    const [feedback, setFeedback] = useState(null);

    // Close Alert dialog
    const handleErrorConfirm = () => {
        setShowErrorDialog(false);
        setErrorMessage("");
    };

    const fetchCsvFile = async (projectName) => {
        try {
            const storageRef = ref(storage, `users/${userID}/${projectName}`);
            const files = await listAll(storageRef);
            const csvFile = files.items.find((file) => file.name.endsWith('.csv'));
            if (csvFile) {
                const filePath = csvFile.fullPath;
                const result = await getSignedUrlCallable({ filePath });
                const signedUrl = result.data.url;
                setFileName(signedUrl);  // update state for use elsewhere
                return signedUrl;  // return for immediate use
            }
        } catch (error) {
            console.error("Error fetching CSV file:", error);
            setErrorMessage("Error reading processed file: " + error.message);
            setShowErrorDialog(true);
        }
        return null;
    };

    // Fetch the *Summary Questions* stored in Firebase global data
    const [summaryQuestions, setSummaryQuestions] = useState([]);
    useEffect(() => {
        const fetchSummaryQuestions = async () => {
            try {
                const docRef = doc(db, 'globalData', 'config');
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setSummaryQuestions(data.summaryQuestions);
                } else {
                    console.log("No global data!");
                }
            } catch (error) {
                console.log("Error fetching summary questions: ", error);
            }
        };

        fetchSummaryQuestions();

    }, []);
    const SummaryData = summaryQuestions;



    const columns = ['Topic', 'Content/Clauses'];
    const [rowData, setRowData] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [summary, setSummary] = useState(false); // set if a summary already exists

    const [fileName, setFileName] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");



    const fetchData = async (fileName, question) => {
        const url = process.env.REACT_APP_QAQTIG;
        const data = { file: fileName, question: question };
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error("API error: " + await response.text());
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("API error:", error);
            throw error;
        }
    };

    const handleSubmit = async () => {
        setIsSubmit(true);

        if (rowData.length === 0) {
            const fileName = await fetchCsvFile(projectName);
            if (fileName) {
                fetchRow(fileName);
            } else {
                console.log(`No CSV file found for project: ${projectName}`);
            }
        }
    };

    useEffect(() => {
        // Fetch the data from Firestore when the component mounts
        const fetchFromFirestore = async () => {
            if (!projectName) {
                return;
            }
            const docRef = doc(db, "users", userID, "contracts", projectName);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();

                // Check if the summary field exists and is not an empty array
                if (data.summary && data.summary.length > 0) { 
                    setRowData(data.summary);
                    setSummary(true);
                    setFeedback(data.summaryFeedback);
                }
            }
        };

        fetchFromFirestore().catch(console.error);
    }, []);

    //--------------------------------
    // Fetching Pricing stored in Firebase global data --------------------
    const [modelPrices, setModelPrices] = useState({});
    useEffect(() => {
        const fetchPricingData = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const selectedModelPricing = data.pricing.find(p => p.model === "GPT3.5"); // or whatever model you need

                if (selectedModelPricing) {
                    setModelPrices(selectedModelPricing.prices);
                }
            } else {
                console.log("No such contract!");
            }
        };

        fetchPricingData().catch((error) => {
            console.log("Error fetching pricing: ", error);
        });
    }, []);

    // 4. Registering Token transactions
    const updateTokens = async (result, userID) => {
        return runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, "users", userID);
            const contractDocRef = doc(userDocRef, "contracts", projectName);
            const transactionRef = doc(collection(userDocRef, "tokenHistory"));

            // Step 4.1: Read all the required documents
            const userDocSnapshot = await transaction.get(userDocRef);
            const contractDocSnapshot = await transaction.get(contractDocRef);

            // Step 4.2: Calculate total token cost for this transaction
            const { emb, input, output } = modelPrices;
            const totalTokensCost = ((result.tokens.qasTokensE * emb) + (result.tokens.qasTokensI * input) + (result.tokens.qasTokensO * output)) * 0.001;

            // Step 4.3: Compute the new values for contractDoc
            // Update User Level Totals
            transaction.update(userDocRef, {
                totalAmount: (userDocSnapshot.data().totalAmount || 0) + totalTokensCost,
                tokensE_Total: (userDocSnapshot.data().tokensE_Total || 0) + result.tokens.qasTokensE,
                tokensI_Total: (userDocSnapshot.data().tokensI_Total || 0) + result.tokens.qasTokensI,
                tokensO_Total: (userDocSnapshot.data().tokensO_Total || 0) + result.tokens.qasTokensO,
            });

            // Update Contract Level Totals
            transaction.update(contractDocRef, {
                qasTokensE: (contractDocSnapshot.data().qasTokensE || 0) + result.tokens.qasTokensE,
                qasTokensI: (contractDocSnapshot.data().qasTokensI || 0) + result.tokens.qasTokensI,
                qasTokensO: (contractDocSnapshot.data().qasTokensO || 0) + result.tokens.qasTokensO,
            });

            // Store Token Usage History
            transaction.set(transactionRef, {
                timeLog: serverTimestamp(),
                tokensE: result.tokens.qasTokensE,
                tokensI: result.tokens.qasTokensI,
                tokensO: result.tokens.qasTokensO
            });
        });
    };
    //--------------------------------

    const saveToFirestore = async (updatedData, uid) => {
        const docRef = doc(db, "users", uid, "contracts", projectName);
        await setDoc(docRef, { summary: updatedData }, { merge: true });
    };

    const fetchRow = async (fileName) => {
        // Initialize an empty array for the updated data
        const updatedData = [];
        // Fetch prediction for each row and populate updatedData
        for (const data of SummaryData) {
            try {
                const result = await fetchData(fileName, data.question); // Fetch prediction
                const prediction = result.answer;
                await updateTokens(result, userID);
                const newData = { ...data, contentCl: prediction }; // Create a new object with updated 'contentCl' field
                updatedData.push(newData); // Push the updated data to the array
                setRowData([...updatedData]); // Add the new row to the state

            } catch (error) {
                console.error('Error fetching prediction:', error);
            }
        }
        setRowData(updatedData);
        saveToFirestore(updatedData, userID).catch(console.error);

    };
    const copyTableToClipboard = () => {
        let tableContent = columns.join("\t") + "\n";  // Header row

        rowData.forEach(row => {
            tableContent += row.topic + "\t" + row.contentCl + "\n";
        });

        navigator.clipboard.writeText(tableContent).then(() => {
            setErrorMessage("Summary table successfully copied to clipboard!");
            setShowErrorDialog(true);
        }).catch(err => {
            setErrorMessage("Could not copy table to clipboard: ", err);
            setShowErrorDialog(true);
        });
    }
    // Function to save feedback in Firestore
    const saveFeedback = async (userID, projectName, feedback) => {
        try {
            await runTransaction(db, async (transaction) => {
                const contractDocRef = doc(db, "users", userID, "contracts", projectName);
                const contractDocSnapshot = await transaction.get(contractDocRef);

                // Get the current data
                const contractData = contractDocSnapshot.data() || {};

                contractData.summaryFeedback = feedback;

                transaction.update(contractDocRef, contractData);
            });

            console.log(`Feedback (${feedback}) saved successfully.`);
        } catch (error) {
            console.error("Error saving feedback:", error);
        }
    };


    const handleThumbsUp = async () => {
        // Handle the logic when thumbs up is clicked.
        setShowErrorDialog(true);
        setErrorMessage('Thanks for the feedback!');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(userID, projectName, "thumbsUp");
        setFeedback("thumbsUp");
    };
    const handleThumbsDown = async () => {
        // Handle the logic when thumbs down is clicked.
        setShowErrorDialog(true);
        setErrorMessage('We appreciate your feedback. We will work on improving.');
        // Save feedback in Firestore and update the icon state
        await saveFeedback(userID, projectName, "thumbsDown");
        setFeedback("thumbsDown");
    };


    return (
        <div>
            <div style={{
                backgroundImage: `url(${qdt1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                maxHeight: '100%',
                maxWidth: '100%',
                minHeight: '18vh',
                zIndex: -1,
            }}></div>
            <div className="qaPageQA">
                <div className="qaTitle1">
                    {!projectName ? (
                        <p>Please select a Contract.</p>
                    ) : summary ? (
                        <p>Your <span className="project">{projectName.toUpperCase()}</span> contract summary is already processed,<br /> press Submit to view it.</p>
                    ) : csvExists ? (
                        <p>Perform a quick summary of the <span className="project">{projectName.toUpperCase()}</span> Contract.</p>
                    ) : (
                        <p>Please ensure you have a processed document to view its summary.</p>
                    )}
                </div>



                <div className="pdfList">
                    <button
                        className="submitButton"
                        onClick={handleSubmit}
                        disabled={isSubmit || !csvExists}
                    >
                        Submit
                    </button> 
                </div>
                <br />
                {isSubmit ? ( 
                    <div className="qaPageR">
                        <div className="STable">
                            <SummaryTable
                                columns={columns}
                                rowData={rowData}
                                totalRows={SummaryData.length}
                                isDataComplete={rowData.length === SummaryData.length}
                            />
                            <div className="feedback-icons bm">
                                <FaCopy onClick={copyTableToClipboard} title="Copy to clipboard" size={22} />

                                {feedback === "thumbsUp" ? (
                                    <FaThumbsUp
                                        onClick={handleThumbsUp}
                                        title="Good answer"
                                        size={22}
                                        color='var(--orange)' // Change color to indicate feedback
                                    />
                                ) : (
                                        <FaThumbsUp
                                            onClick={handleThumbsUp}
                                            title="Good answer"
                                            size={22}
                                        />
                                    )
                                }
                                {feedback === "thumbsDown" ? (
                                    <FaThumbsDown
                                        onClick={handleThumbsDown}
                                        title="Not a good answer"
                                        size={22}
                                        color='var(--orange)' // Change color to indicate feedback
                                    />
                                ) : (
                                        <FaThumbsDown
                                            onClick={handleThumbsDown}
                                            title="Not a good answer"
                                            size={22}
                                        />
                                    )
                                }
                            </div>
                            
                        </div>

                    </div>
                ) :
                    (<div></div>)
                }
            </div>
            <AlertDialog
                show={showErrorDialog}
                title="Alert"
                message={errorMessage}
                onConfirm={handleErrorConfirm}
                confirmLabel="OK"
            />
        </div>
    );
}

export default SummaryDemo; 