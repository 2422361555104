import React from 'react';
import HeaderTw from "./HeaderTw";
import { ResponsiveContainer, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from './ResponsiveComponents';

import "./tw.css";

import q1 from '../assets/site/q1.svg';
import q2 from '..//assets/site/q2.svg';
import q3 from '../assets/site/q3.svg';
import q4 from '../assets/site/q4.svg';
import qtig from '../assets/qtig.svg';


function QPage({ user }) {

    return (
        <>
            <HeaderTw user={user} />
            <div className="Tw-Body">
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <ResponsiveImage src={q1} alt="image1" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <p className="Tw-text1"><span style={{ color: 'var(--orange)' }}>Welcome!<br /> You need to log in to try</span> <img src={qtig} alt="qtig" className="qtigInLine" />.</p>
                        </div>
                    </ResponsiveElement2>
                </ResponsiveContainer>

                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <p>Once you have logged in, upload your PDF document.</p>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <ResponsiveImage src={q2} alt="image1" />
                    </ResponsiveElement1>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement1>
                        <ResponsiveImage src={q3} alt="image2" />
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                        <div className="Tw-text">When the upload is finished the file will be processesed and saved in the cloud. The processes can take few minutes depending on the size of the document.</div>
                    </ResponsiveElement2>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement2>
                        <div className="Tw-text">
                            <p>Then, you can ask <b>qtig</b> questions about your document!</p>
                        </div>
                    </ResponsiveElement2>
                    <ResponsiveElement1>
                        <ResponsiveImage src={q4} alt="image1" />
                    </ResponsiveElement1>
                </ResponsiveContainer>
                <ResponsiveContainer>
                    <ResponsiveElement1>
                    </ResponsiveElement1>
                    <ResponsiveElement2>
                    </ResponsiveElement2>
                </ResponsiveContainer>

            </div>
        </>
    );
}

export default QPage; 