import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import '../css/SummaryTable.css';


const SummaryTable = ({ columns, rowData, totalRows, isDataComplete, selectedQuestions }) => {
    const [loadingData, setLoadingData] = useState(
        isDataComplete ? Array(totalRows).fill(false) : Array(totalRows).fill(true)
    );
    useEffect(() => {
        // Create an updated loadingData array based on selectedQuestions
        const updatedLoadingData = selectedQuestions.map(questionId =>
            // If there is corresponding data in rowData, set loading to false, otherwise true
            rowData.some(row => row.id === questionId) ? false : true
        );

        setLoadingData(updatedLoadingData);
    }, [rowData, isDataComplete, selectedQuestions]); // Include selectedQuestions as a dependency


    return (
        <div className='summaryTable'>
            <table>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {selectedQuestions.map((questionId, index) => {
                        const questionData = rowData.find(row => row.id === questionId);
                        const isLoading = loadingData.length > index ? loadingData[index] : false;

                        return (
                            <tr key={questionId}>
                                {isLoading ? (
                                    // Show loading animation for the row if it's not yet loaded
                                    <>
                                        <td className="blink-box-row"></td>
                                        <td className="blink-box-row"></td>
                                    </>
                                ) : questionData ? (
                                    // Show the actual row data once it's loaded
                                    <>
                                        <td>{questionData.topic}</td>
                                        <td>
                                            <ReactMarkdown skipHtml={true}>
                                                {questionData.contentCl}
                                            </ReactMarkdown>
                                        </td>
                                    </>
                                ) : (
                                    // This case handles if there's no data yet (e.g., still fetching)
                                    <>
                                        <td>Loading...</td>
                                        <td>Loading...</td>
                                    </>
                                )}
                            </tr>
                        );
                    })}
                </tbody>

            </table>
        </div>
    );
};

export default SummaryTable;
