import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../css/EditDialog.css';
import { FaFolderPlus, FaTrashAlt, FaFileUpload, FaSync } from "react-icons/fa";



function EditDialog({ show, title, onCancel, onDelete, onAddFolder, onAddPdf, onSync, cancelLabel}) {
    return (
        <Modal className="modalEdit" show={show} onHide={onCancel} dialogClassName="modalEdit-dialog">
            <Modal.Header className="modalEditHeader" closeButton>
                <Modal.Title className="modalEditTitle">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalEditBody">
                <div className="editOption" onClick={onAddPdf}>
                    <div className="editOptionButton" >
                        <FaFileUpload />
                    </div>
                    <p className="editOptionText"><b>UPLOAD</b> files to the folder</p>
                </div>
                <div className="editOption" onClick={onSync}>
                    <div className="editOptionButton" >
                        <FaSync />
                    </div>
                    <p className="editOptionText"><b>SYNCHRONISE</b> all files in folder</p>
                </div>
                <div className="editOption" onClick={onAddFolder}>
                    <div className="editOptionButton" >
                        <FaFolderPlus />
                    </div>
                    <p className="editOptionText"><b>ADD</b> a new sub-folder</p>
                </div>
                <div className="editOption" onClick={onDelete}>
                    <div className="editOptionButton"  >
                        <FaTrashAlt />
                    </div>
                    <p className="editOptionText"><b>DELETE</b> current folder</p>
                </div>

            </Modal.Body>
            <Modal.Footer className="modalFooter">
                <div>
                    <br />
                </div>
                
            </Modal.Footer>
        </Modal>
    );
}

export default EditDialog;