import React, { useState, useEffect } from 'react';
import {
    collection,
    doc,
    updateDoc,
    deleteDoc,
    query,
    where,
    getDoc,
    getDocs,
    db,
} from '../../Firebase';
import { Modal, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import "../css/Dialog.css";
import "../css/pdf.css";
import { FaThumbsUp, FaThumbsDown, FaRegTrashAlt } from 'react-icons/fa';



function GeminiModal({ knowledge, geminiModalVisible, closeGeminiModal, userID, projectName }) {

    const [qas, setQas] = useState([]);
    const [deletedItem, setDeletedItem] = useState(0);
    const project = projectName.split('/').join('_');
    const projectPath = projectName.split('/')[0];
    const [isLoading, setIsLoading] = useState(false);


    const fetchQAsByProject = async (project, knowledge) => {
        const qasColRef = collection(db, "users", userID, "llm"); 
        let q;
        if (knowledge !== 'generalAll') { 
            const start = project;
            const end = project + '\uf8ff'; // A high Unicode character to act as a suffix in the range
            q = query(qasColRef, where("project", ">=", start), where("project", "<", end));
        } else {
            q = query(qasColRef); 
        }
        const querySnapshot = await getDocs(q);
        const qas = [];
        querySnapshot.forEach((doc) => {
            qas.push({ id: doc.id, ...doc.data() });
        });
        return qas;
    };
    const fetchQAsSpecificPath = async (projectPath, project) => {
        const qasColRef = collection(db, "users", userID, "contracts", projectPath, "qa");
        const pathSnap = await getDocs(qasColRef);
        let pathQAs = [];

        pathSnap.forEach((doc) => {
            // Check if the document ID includes the projectPrefix
            if (doc.id.startsWith(project)) {
                const qasArray = doc.data().qas || [];
                // Map each QA to include a unique ID based on its index
                const mappedQAs = qasArray.map((qa, index) => ({
                    id: `${doc.id}-${index}`, // Use the document ID and index for a unique identifier
                    ...qa
                }));
                pathQAs = [...pathQAs, ...mappedQAs]; // Append the mapped QAs to the results
            }
        });

        return pathQAs;
    };
    const fetchQAsSpecificAll = async () => {
        const contractsRef = collection(db, "users", userID, "contracts");
        const contractsSnapshot = await getDocs(contractsRef);
        const allQAs = [];

        // Iterate over each contract document
        for (const contractDoc of contractsSnapshot.docs) {
            const qaRef = collection(db, "users", userID, "contracts", contractDoc.id, "qa");
            const qaSnapshot = await getDocs(qaRef);
            // Iterate over each qa document within the current contract
            for (const qaDoc of qaSnapshot.docs) {   
                const qaData = qaDoc.data();
                const qasArray = qaData.qas || [];
                const projectPath = contractDoc.id;
                // Map over the qas array to construct an array with unique IDs for each QA
                const qasWithIds = qasArray.map((qa, index) => ({
                    id: `${projectPath}-${qaDoc.id}-${index}`, // Construct a unique ID for each QA
                    ...qa
                }));

                allQAs.push(...qasWithIds);
            }
        }

        return allQAs;
    };
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Start loading
            let fetchedQas = [];

            try {
                if (knowledge === "general" || knowledge === "generalAll") {
                    fetchedQas = await fetchQAsByProject(project, knowledge);
                } else if (knowledge === "specific") {
                    fetchedQas = await fetchQAsSpecificPath(projectPath, project);
                } else if (knowledge === "specificAll") {
                    fetchedQas = await fetchQAsSpecificAll();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setQas(fetchedQas);
                setIsLoading(false); // End loading
            }
        };

        if (geminiModalVisible) {
            fetchData();
        }
    }, [knowledge, geminiModalVisible, deletedItem, project, projectPath]);


    const deleteGenQA = async (qaId) => {
        const qasDocRef = doc(db, "users", userID, "llm", qaId);
        try {
            await deleteDoc(qasDocRef);
            console.log("Document successfully deleted!");
            setDeletedItem(count => count + 1);; 
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };
    const deleteSpecQA = async (qaId, projectPath, project) => {
        const qasDocRef = doc(db, "users", userID, "contracts", projectPath, 'qa', project);
        const docSnap = await getDoc(qasDocRef);
        if (docSnap.exists()) {
            const currentQAs = docSnap.data().qas || [];
            const updatedQAs = currentQAs.filter((qa, index) => `${project}-${index}` !== qaId);
            await updateDoc(qasDocRef, { qas: updatedQAs });
            setDeletedItem(count => count + 1);
        } else {
            console.log("No such document!");
        }

    };


    const handleCloseModal = () => {
        setQas([]); // Reset the `qas` state to empty
        closeGeminiModal(); // Call the parent's close modal function
    };

    return (
        <Modal show={geminiModalVisible} onHide={handleCloseModal}  scrollable={true} className="modalPDF" backdrop="static" dialogClassName="modalPDF-dialog">

            <Modal.Header className="modalPDF-header">
                <Modal.Title className="modalPDF-title">
                    <span className="modalPDF-title1">Gemini-{knowledge}</span>
                    <span className="modalPDF-title2">{projectName}</span>
                    <span className="modalPDF-title3">&#x1F50E;&#xFE0E; CTRL-F</span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalPDF-body"  >
                {isLoading ? (
                    <div className="modalGemini-qas">
                        <div className="blink-box"></div>
                    </div> 
                ) : knowledge === "general" || knowledge === "generalAll"  ? (
                    <div className="modalPDF-document" >
                        {qas.length > 0 ? (
                            qas.map((qa) => (
                                <div key={qa.id} className="modalGemini-qas">
                                    <ReactMarkdown skipHtml={true} children={`${qa.question}`} className="chat-text bold geminiQ" />
                                    <ReactMarkdown skipHtml={true} children={`Date: ${qa.createdAt.toDate().toLocaleDateString('en-UK')}`} className="chat-text geminiA" />
                                    <ReactMarkdown skipHtml={true} children={`Ref: ${qa.project}`} className="chat-text bold geminiQ s" />
                                    <ReactMarkdown skipHtml={true} children={qa.answer} className="chat-text geminiA" />
                                    <div
                                        style={{alignText:'right'} }
                                        className="feedback-icons"
                                        title="Delete Q&A"
                                        onClick={() => deleteGenQA(qa.id)}
                                    >
                                        <FaRegTrashAlt size={22} color='var(--orange)' />
                                    </div>
                                </div>
                            ))
                        ) : (<div className="modalGemini-qas">
                                <p className="chat-text geminiA">No Q&As found in this path.</p>
                            </div>
                        )}
                    </div>
                ) : knowledge === "specific" || knowledge === "specificAll" ? (
                    <div className="modalPDF-document" >
                        {qas.length > 0 ? (
                            qas.map((qa) => (
                                <div key={qa.id} className="modalGemini-qas">
                                    <ReactMarkdown skipHtml={true} children={`${qa.query}`} className="chat-text bold geminiQ" />
                                    <ReactMarkdown skipHtml={true} children={`Ref: ${qa.id}`} className="chat-text bold geminiQ s" />
                                    <ReactMarkdown skipHtml={true} children={qa.prediction} className="chat-text geminiA" />
                                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                                        <div className="feedback-icons" style={{ marginLeft: '20px'}}>
                                            {qa.feedback === "thumbsUp" ? (
                                                <FaThumbsUp size={22} color='var(--orange)'/>
                                            ) : (qa.feedback === "thumbsDown" ? (
                                                <FaThumbsDown size={22} color='var(--orange)' />
                                                ) : (null)

                                            )}
                                        </div>
                                        <div
                                            className="feedback-icons"
                                            title="Delete Q&A"
                                            onClick={() => deleteSpecQA(qa.id, projectPath, project)}
                                        >
                                            <FaRegTrashAlt size={22} color='var(--orange)' />
                                        </div>
                                    </div>
                                </div>
                            ))
                            ) : (<div className="modalGemini-qas">
                                <p className="chat-text geminiA">No Q&As found in this path.</p>
                            </div>
                        )}
                    </div>
                ) : (<p></p>)}
            </Modal.Body>
            <Modal.Footer className="modalPDF-footer">
                
                <Button className="confirmButtonPDF" variant="dark" onClick={handleCloseModal} disabled={isLoading}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
export default GeminiModal;
