import styled from 'styled-components';

const ResponsiveContainerQA = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 5vh auto;
  max-width: 100%;
  

  @media (max-width: 768px), (orientation: portrait) {
    max-width: 100%;
    display: flex;
    flex-direction: column;

  }
`;


const ResponsiveElementQA1 = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding: 2vh 1vw 0 1vw;
  margin: 3vh 0 0 0;

  @media (max-width: 768px), (orientation: portrait) {
    width: 100%;
    order: 1;
  }
`;

const ResponsiveElementQA2 = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding: 2vh 1vw 0 1vw;
    margin: 3vh 0 0 0;

  @media (max-width: 768px), (orientation: portrait) {
    width: 100%;
    order: 2;
  }
`;


export { ResponsiveContainerQA,  ResponsiveElementQA1, ResponsiveElementQA2 };
