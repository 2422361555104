import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../css/square.css';
import qtig from '../../assets/site/qtig1a.png';

const x0 = -10;
const x1 = 30;
const wx = 7;
const x2 = x1 + wx;
const xc = x1 + wx / 2;
const xm1 = x1 + wx / 5;
const xm2 = x1 + wx - wx / 5;
const x3 = x2 + wx * 3;

const y7 = 5;
const y6 = 18;
const y5a = 30;
const y5 = 40;
const y4 = 55;
const y3 = 70;
const y2 = 85;
const y1 = 105;

const pointsArray = [
    [x1, y1], [x1, y5], [xm1, y6], [xc, y7], [xm2, y6], [x2, y5],
    [x2, y1], [x1, y2], [x2, y2], [x1, y3], [x2, y3], [x1, y4],
    [x2, y4], [x1, y5], [x2, y5], [xm1, y6], [xm2, y6], [x1, y5],
    [x2, y5], [x1, y4], [x2, y4], [x1, y3], [x2, y3], [x1, y2],
    [x2, y2], [x1, y1]
];
const pointsArray2 = [
    [x0, y4], [xc, y7], [x3, y5a], [(x3 - 2), y5], [( x3 - 1), (y5a-1)]];
const pointsArray3 = [
    [(x3 - 2), y5], [(x3 - 2.7), y5 + 3], [(x3 - 6), y5 + 5], [(x3 - 5), y5 + 8], [(x3 - 6), y5 + 5], [(x3 - 2.7), y5 + 3], [(x3), y5 + 5.6], [(x3 - 2), y5 + 9]];

const pointsString = pointsArray.map(point => point.join(',')).join(' ');
const pointsSt2 = pointsArray2.map(point => point.join(',')).join(' ');
const pointsSt3 = pointsArray3.map(point => point.join(',')).join(' ');

function QtigAnimation() {
    const [animationPlay, setAnimationPlay] = useState(false);

    useEffect(() => {
        // Start the animation initially by setting animationPlay to true
        setAnimationPlay(true);
    }, []);

    const handleMouseEnter = () => {
        // Restart the animation by toggling the animationPlay state
        setAnimationPlay(false);
        setTimeout(() => setAnimationPlay(true), 10); // Slight delay before restarting the animation
    };

    return (
        <div className="qtig-container">
            <svg onMouseEnter={handleMouseEnter} className="qtig-content" viewBox="0 0 200 100">
                <defs>
                    <filter id="shadow" x="-10%" y="-10%" width="160%" height="160%">
                        <feDropShadow dx="1" dy="1" stdDeviation="2" floodColor='rgba(0,0,0,0.5)' />
                    </filter>
                    <filter id="shadow1" x="-5%" y="-10%" width="120%" height="120%">
                        <feDropShadow dx="1" dy="1" stdDeviation="1" floodColor='rgba(0,0,0,0.15)' />
                    </filter>
                    <clipPath id="clipRect">
                        <rect x="65" y="25" width="80" height="60" />
                    </clipPath>
                </defs>
                {animationPlay && (
                    <>
                        {/* rect */}
                        <motion.rect
                            x='95'
                            y='10'
                            width='55'
                            height='80'
                            fill='#FFFFFF'
                            initial={{ opacity: 0, rotate: 0, scale: 0.7 }}
                            animate={{ opacity: 1, rotate: -200, scale: 1 }}
                            transition={{ duration: 1 }}
                            filter="url(#shadow)"
                        />
                        {/* rect1 */}
                        <motion.rect
                            x='60'
                            y='10'
                            width='55'
                            height='80'
                            fill='#F5F5F5'
                            initial={{ opacity: 0, rotate: 0, scale: 0.7 }}
                            animate={{ opacity: 1, rotate: 200, scale: 1 }}
                            transition={{ duration: 1.1 }}
                            filter="url(#shadow)"
                        />
                        {/* rect2 */}
                        <motion.rect
                            x='-15'
                            y='60'
                            width='25'
                            height='60'
                            fill='black'
                            initial={{ opacity: 0, rotate: 0 }}
                            animate={{ opacity: 1, rotate: 40 }}
                            transition={{ duration: 1 }}
                            filter="url(#shadow)"
                        />
                        {/* rect3 */}
                        <motion.rect
                            x='-12'
                            y='70'
                            width='15'
                            height='40'
                            fill='#FEF0D8'
                            initial={{ opacity: 0, rotate: 0 }}
                            animate={{ opacity: 1, rotate: 30 }}
                            transition={{ duration: 1.1 }}
                        />
                        {/* rect4 */}
                        <motion.rect
                            x='195'
                            y='60'
                            width='25'
                            height='60'
                            fill='black'
                            initial={{ opacity: 0, rotate: 0 }}
                            animate={{ opacity: 1, rotate: -40 }}
                            transition={{ duration: 1 }}
                            filter="url(#shadow)"
                        />
                        {/* rect5 */}
                        <motion.rect
                            x='197'
                            y='70'
                            width='15'
                            height='40'
                            fill='#FEF0D8'
                            initial={{ opacity: 0, rotate: 0 }}
                            animate={{ opacity: 1, rotate: -30 }}
                            transition={{ duration: 1.2 }}
                        />
                        {/* rect6 */}
                        <motion.rect
                            x='65'
                            y='25'
                            width='80'
                            height='60'
                            fill='#FEF0D8'
                            initial={{ opacity: 0, scale: 0.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1.3 }}
                            filter="url(#shadow1)"
                        />
                        {/* qtig */}
                        <motion.image
                            href={qtig}
                            x='65'
                            y='25'
                            width='80'
                            height='60'
                            clipPath="url(#clipRect)"
                            initial={{ opacity: 0, scale: 0.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1.3 }}
                        />
                        <motion.g
                            initial={{ opacity: 0, rotate: -4 }}
                            animate={{ opacity: 1, rotate: -8 }}
                            transition={{ duration: 1 }}
                            style={{ transformOrigin: 'center' }}
                            filter="url(#shadow)"
                        >
                            {/* pol1 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="0.4"
                                points={pointsString}
                            />
                            {/* pol2 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="1"
                                points={pointsSt2}
                            />
                            {/* pol3 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="0.3"
                                points={pointsSt3}
                            />
                        </motion.g>
                        <motion.g
                            initial={{ opacity: 0, rotate: -4, scaleX: -1, x:160 }}
                            animate={{ opacity: 1, rotate: -8, scaleX: -1, x: 160 }}
                            transition={{ duration: 1 }}
                            style={{ transformOrigin: 'center' }}
                            filter="url(#shadow)"
                        >
                            {/* pol1 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="0.4"
                                points={pointsString}
                            />
                            {/* pol2 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="1"
                                points={pointsSt2}
                            />
                            {/* pol3 */}
                            <motion.polyline
                                fill="none"
                                stroke="#F06E1E"
                                strokeWidth="0.3"
                                points={pointsSt3}
                            />
                        </motion.g>
                    </>
                )}
            </svg>
        </div>
    );
}

export default QtigAnimation;
