import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Firebase";
import ScrollToTop from './components/service/ScrollToTop';
import React from 'react';
import { AppContextProvider } from './AppContext';

import Tw from './Tw';
import App from './App';
import Auth from './Auth';
import AboutUs from './twintig/AboutUs';
import PrivacyPolicy from './twintig/Privacy';
import PlaceholderPage from './twintig/PlaceholderPage';

function Website() {

    const [user] = useAuthState(auth);

    return (
        <AppContextProvider>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Tw user={user}/>} />
                    <Route path="qtig/*" element={<App user={user} />} />
                    <Route path="login/*" element={<Auth />} />
                    <Route path="about" element={<AboutUs user={user}/>} />
                    <Route path="privacy" element={<PrivacyPolicy user={user} />} />
                    <Route path="placeholder" element={<PlaceholderPage user={user} />} />
                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        </AppContextProvider>
    );
}

export default Website;
