import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AppContextProvider  } from './AppContext';
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";
import Reset from "./components/authentication/Reset";
import Dashboard from "./components/authentication/Dashboard";
import HeaderAuth from "./components/page/HeaderAuth";

import "./components/css/auth.css";

function Auth() {
    return (
        <div>
          <HeaderAuth />
          <AppContextProvider>
                <div>
                    <Routes>
                            <Route  path='/' element={<Login />} />
                            <Route  path='register' element={<Register />} />
                            <Route  path='reset' element={<Reset />} />
                            <Route  path='dashboard' element={<Dashboard />} />
                    </Routes>
                </div>
          </AppContextProvider>
        </div>
  );
}

export default Auth; 
