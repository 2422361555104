import { AppContext } from '../../AppContext'
import React, { useContext, useState, useEffect } from 'react';
import PdfUpload from '../service/pdfUpload';
import ConfirmationDialog from '../utils/ConfirmationDialog';
import AlertDialog from '../utils/AlertDialog';
import { saveCSVToStorage } from "../service/SaveCSVStore";

import {
    storage,
    ref,
    listAll,
    getDownloadURL,
    deleteObject,
    db,
    runTransaction,
    updateDoc,
    getDoc,
    doc,
    collection,
    serverTimestamp,
} from '../../Firebase';

import "../css/GPTtables.css";
import "../css/GPTApp.css";
import qdt1 from '../../assets/qtig/qdt1.png';
import deleteIcon from '../../assets/delete.png';

function PdfDemo() {

    const { userID, projectName, dropVal, setDropVal, csvExists, getCsvFile, userStatus, loading} = useContext(AppContext).contextValue;

    useEffect(() => {
        if (!loading) {
            if (csvExists && userStatus === 'demo') {
                setDropVal(1);
            } else {
                setDropVal(0);
            }
        }
    }, [csvExists, userStatus, loading]);

    const [directoryName, setDirectoryName] = useState('');
    const [maxFiles, setMaxFiles] = useState(userStatus === 'demo' ? 1 : 100);
    const [validationMessage, setValidationMessage] = useState('');
    const [validationVal, setValidationVal] = useState(0);
    const [validationPdfName, setValidationPdfName] = useState('');

    const [isValidationInProgress, setIsValidationInProgress] = useState(false);
    const [deleteDueToValidationModalVisible, setDeleteDueToValidationModalVisible] = useState(false);

    const [fileToDelete, setFileToDelete] = useState(null);

    const [fileUploadTimestamp, setFileUploadTimestamp] = useState(null);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [prediction, setPrediction] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isReading, setIsReading] = useState(false);

    const [message, setMessage] = useState('');
    const [apiMessage, setApiMessage] = useState('');
    const [error, setError] = useState('');
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [isLoadingProjects, setIsLoadingProjects] = useState(false);
    const [availableProjects, setAvailableProjects] = useState([]);

    const fetchAvailableProjects = async () => {
        try {
            const storageRef = ref(storage, `users/${userID}`);
            const folders = await listAll(storageRef);
            const projects = folders.prefixes.map((folderRef) => folderRef.name);
            setAvailableProjects(projects);
        } catch (error) {
            console.error("Error fetching available projects:", error);
        } finally {
            setIsLoadingProjects(false);
        }
    };
    useEffect(() => {
        fetchAvailableProjects();
    }, []);



    const openDeleteModal = (fileName) => {
        setFileToDelete(fileName);
        setDeleteModalVisible(true);
    };

    const fetchFilesData = async (projectName) => {
        try {
            const storageRef = ref(storage, `users/${userID}/${projectName}/pdfs`);
            const files = await listAll(storageRef);
            const filesData = await Promise.all(
                files.items.map(async (fileRef) => {
                    const fileUrl = await getDownloadURL(fileRef);
                    return { fileName: fileRef.name, fileUrl };
                })
            );
            setTableData(filesData);
        } catch (error) {
            console.error("Error fetching files data:", error);
        } finally {
            setLoadingFiles(false); // Set the state to false after the async function is done
            setIsReading(false);
        }
    };
    useEffect(() => {
        if (projectName) {
            setIsReading(true);
            fetchFilesData(projectName);
        } else {
            setTableData([]);
        }
    }, [projectName, fileUploadTimestamp]);


    const handleDirectoryChange = (newDirectoryName) => {

        setDirectoryName(newDirectoryName);
        setFileUploadTimestamp(Date.now());
    };

    useEffect(() => {
        if (userStatus === 'demo' && directoryName) {
            validateFiles(directoryName);
        } else {
            setValidationVal(1);
        }
    }, [directoryName, userStatus]);


    const validateFiles = async (directoryName) => {
        setIsValidationInProgress(true);
        const validationUrl = process.env.REACT_APP_VALQTIG;  // Your validation API URL
        try {
            const response = await fetch(validationUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ directory: directoryName }),
            });

            if (response.ok) {
                const validationResult = await response.json();
                const newValidationMessage = validationResult.message;
                setValidationMessage(newValidationMessage);
                setValidationVal(validationResult.validate);
                setValidationPdfName(validationResult.pdf);

                // Automatically delete the file if validationVal is 0
                if (validationResult.validate === 0 && validationResult.pdf !== 'null') {
                    setFileToDelete(validationResult.pdf);
                    setDeleteDueToValidationModalVisible(true);                   
                }
                if (validationResult.validate === 1) {
                    setDropVal(1);
                }   

            } else {
                throw new Error('Validation API response was not ok');
            }
        } catch (error) {
            console.error('Validation API error:', error);
        } finally {
            setIsValidationInProgress(false);  // End validation, set isValidationInProgress to false
        }

    };

    //--------------------------------
    // Fetching Pricing stored in Firebase global data --------------------
    const [modelPrices, setModelPrices] = useState({});
    useEffect(() => {
        const fetchPricingData = async () => {
            const docRef = doc(db, 'globalData', 'config');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const selectedModelPricing = data.pricing.find(p => p.model === "GPT3.5"); // or whatever model you need

                if (selectedModelPricing) {
                    setModelPrices(selectedModelPricing.prices);
                }
            } else {
                console.log("No such contract!");
            }
        };

        fetchPricingData().catch((error) => {
            console.log("Error fetching pricing: ", error);
        });
    }, []);

    // 4. Registering Token transactions
    const updateTokens = async (result, userID) => {
        return runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, "users", userID);
            const contractDocRef = doc(userDocRef, "contracts", projectName);
            const transactionRef = doc(collection(userDocRef, "tokenHistory"));

            // Step 4.1: Read all the required documents
            const userDocSnapshot = await transaction.get(userDocRef);
            const contractDocSnapshot = await transaction.get(contractDocRef);

            // Step 4.2: Calculate total token cost for this transaction
            const { emb } = modelPrices;
            const totalTokensCost = (result.total_tokens * emb)  * 0.001;

            // Step 4.3: Compute the new values for contractDoc
            // Update User Level Totals
            transaction.update(userDocRef, {
                totalAmount: (userDocSnapshot.data().totalAmount || 0) + totalTokensCost,
                tokensE_Total: (userDocSnapshot.data().tokensE_Total || 0) + result.total_tokens,
            });

            // Update Contract Level Totals
            transaction.update(contractDocRef, {
                docTokensE: (contractDocSnapshot.data().docTokensE || 0) + result.total_tokens
            });

            // Store Token Usage History
            transaction.set(transactionRef, {
                timeLog: serverTimestamp(),
                tokensE: result.total_tokens
            });
        });
    };
    //--------------------------------

    const handleSubmit = async (event) => {
        if (!directoryName || !projectName) {
            alert("Please fill in all required fields");
            return;
        }
        event.preventDefault();

        setIsLoading(true);
        const url = process.env.REACT_APP_PDFQTIG;
        const data = {
            directory: directoryName, // Pass the list of file URLs
        };
        console.log('Submitting form...', data);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                // This is the new part:
                const text = await response.text();
                console.error('Unexpected response:', text);
                throw new Error('Server response was not ok');
            }
            const result = await response.json();
            const csvData = result.csv_data;

            setPrediction(csvData);
            setApiMessage('The PDF(s) have been processed. Uploading to cloud Storage...'); 

            await updateTokens(result, userID);
            setIsLoading(false);

        } catch (error) {
            console.error('API error:', error);
            setIsLoading(false);
            }
    };
    useEffect(() => {
        const saveCSV = async () => {
            if (prediction) {
                await handleSaveCSV();
                // Refresh the csvExists value
                await getCsvFile(projectName);
            }
        };
        saveCSV();
    }, [prediction, projectName]);


    const handleSaveCSV = async () => {
        try {
            await saveCSVToStorage(prediction, projectName, userID);
            setMessage('Processed file saved to storage!');
        } catch (error) {
            console.error(error);
            setMessage('Error saving processed file to storage');
        }
    };
    const handleDeletePdf = async () => {
        setIsDeleting(true);
        try {

            // 1. Delete PDF from storage
            const fileRef = ref(storage, `users/${userID}/${projectName}/pdfs/${fileToDelete}`);
            await deleteObject(fileRef);

            if (userStatus === 'demo') {
                try {
                    // 2. Delete CSV from storage
                    const csvRef = ref(storage, `users/${userID}/${projectName}/${projectName}.csv`);
                    await deleteObject(csvRef);
                } catch (csvError) {
                    console.warn("No CSV found:", csvError);
                }

                // 2.1 Refresh the csvExists value
                await getCsvFile(projectName);

                // 3. Clear data in Firestore
                const docRef = doc(db, "users", userID, "contracts", projectName);
                await updateDoc(docRef, {
                    qas: [],
                    summary: []
                });
                setValidationMessage('');
                setValidationVal(0);
                setValidationPdfName('');
                setDropVal(0);
            }


            setTableData((prevTableData) => prevTableData.filter((fileData) => fileData.fileName !== fileToDelete));
            if (fetchAvailableProjects) {
                fetchAvailableProjects();
            }

        } catch (error) {
            console.error("Error deleting file:", error);
        } finally {
            setIsDeleting(false);
            setDeleteModalVisible(false);            
        }
    };

    return (
        <div className="qaPage">
            <div style={{
                backgroundImage: `url(${qdt1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                maxHeight: '100%',
                maxWidth: '100%',
                minHeight: '18vh',
                zIndex: -1,
            }}></div>
            <div className="qaPageQA">
                <div className="upContainer">
                    <p className="pdfUploadTitle">
                        {projectName
                            ? "Upload a PDF file (Demo limited)"
                            : "No Contract selected"}
                    </p>
                    <PdfUpload
                        userID={userID}
                        projectName={projectName}
                        onDirectoryChange={handleDirectoryChange}
                        onUpdateProjects={fetchAvailableProjects}
                        onStartLoading={() => setLoadingFiles(true)}
                        onFinishLoading={() => setLoadingFiles(false)}
                        isDeleting={isDeleting}
                        dropVal={dropVal}
                        maxFiles={maxFiles}
                    />

                    {loadingFiles && (
                        <div>
                            <p className="pdfList">Loading PDF files...</p>
                            <div className="loading-spinner-container1">
                                <div className="loading-spinner1"></div>
                            </div>
                        </div>
                    )}

                    {isDeleting && (
                        <div>
                            <p className="pdfList"> Deleting PDF file...</p>
                            <div className="loading-spinner-container1">
                                <div className="loading-spinner1"></div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="upContainer">
                    <div className="pdfList">
                        Pdf file(s) in the <span className="project">{projectName.toUpperCase()}</span> contract:
                    </div>
                    <div className="PdocTableD">
                        {isReading ? (
                            <div className="blink-box"></div>  // or your custom loading indicator
                        ) : (
                                tableData.length > 0 && (
                                    <table>
                                        <tbody>
                                            {tableData.map((fileData, index) => (
                                                <tr key={index} >
                                                    <td></td>
                                                    <td>{fileData.fileName}</td>
                                                    <td>
                                                        <button
                                                            className="delete-pdf-button"
                                                            onClick={() => openDeleteModal(fileData.fileName)}
                                                            disabled={isDeleting}
                                                        >
                                                            <img src={deleteIcon} alt="Delete" width="13px" height="13px" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )
                            )}
                        <ConfirmationDialog
                            show={deleteModalVisible}
                            title="Delete File"
                            message={
                                <>
                                    {`Please confirm the removal of "${fileToDelete}".`}
                                    <br /><br />
                                    {userStatus === 'demo' && `Note: This will also clear ALL processed data linked to this Contract.`}
                                </>
                            }
                            onConfirm={handleDeletePdf}
                            onCancel={() => setDeleteModalVisible(false)}
                            confirmLabel="Delete"
                            cancelLabel="Cancel"
                        />
                    </div>

                    <div className='pdfList'>
                        {isValidationInProgress && (
                            <div>
                                <p className="pdfList"> Validating PDF file...</p>
                                <div className="loading-spinner-container1">
                                    <div className="loading-spinner1"></div>
                                </div>
                            </div>
                        )}
                        {!isValidationInProgress && (
                            <div className="pdfList">
                                {validationMessage}
                            </div>
                        )}

                        <AlertDialog
                            show={deleteDueToValidationModalVisible}
                            title="Validation Failed"
                            message={<><span>{validationMessage}</span><br />The file will be deleted.</>}
                            
                            onConfirm={() => {
                                handleDeletePdf();
                                setDeleteDueToValidationModalVisible(false);
                            }}
                            confirmLabel="OK"
                        />

                        {isLoading && (
                            <div>
                                <p className="pdfList"> Processing and saving {directoryName.length} PDF file(s)... </p>

                                {apiMessage ? (
                                    <p className="pdfList">{apiMessage}</p>
                                ) : (
                                        <p className="pdfList">
                                            This process can take a few minutes. Thank you for your patience.
                                        </p>
                                    )}

                                <div className="loading-spinner-container1">
                                    <div className="loading-spinner1"></div>
                                </div>
                            </div>
                        )}
                        {!isLoading && prediction && message && (
                            <div>
                                <p className="pdfList">
                                    {message}
                            </p>
                            </div>
                        )}

                        <button
                            className={`action_btn${(isLoading || !directoryName || !projectName || isValidationInProgress || (validationVal === 0) || prediction) ? ' action_btn_disabled' : ''}`}
                            type="submit"
                            disabled={isLoading || !directoryName || !projectName || isValidationInProgress || (validationVal === 0) || prediction}
                            onClick={handleSubmit}
                        >
                            Process PDFs
                        </button>
                    </div>
                    <div className="text1">
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PdfDemo;
