import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import addFile from '../../assets/addFile.png';
import '../css/GPTApp.css';
import { storage, ref, uploadBytes, getSignedUrlFolderCallable } from '../../Firebase';

const acceptedFileType = { 'application/pdf': ['.pdf'] };

function PdfUpload({ userID, projectName, onDirectoryChange, onStartLoading, onFinishLoading, isDeleting, dropVal, maxFiles = 1 }) {

    const [directoryName, setDirectoryName] = useState('');

    // Determine whether the Dropzone should be disabled
    const isDisabled = !projectName || dropVal === 1 ;

    const handleFileUpload = async (userID, projectName, files) => {
        // Create a reference to the PDFs folder in the user's Storage directory
        const pdfsRef = ref(storage, `users/${userID}/${projectName}/pdfs`);

        // Upload each file to the PDFs folder
        const uploadTasks = files.map((file) => {
            const fileRef = ref(storage, `${pdfsRef.fullPath}/${file.name}`);
            return uploadBytes(fileRef, file);
        });

        // Wait for all upload tasks to complete
        await Promise.all(uploadTasks);

        // Generate a signed URL for the PDFs folder
        const urlResponse = await getSignedUrlFolderCallable({
            folderPath: `users/${userID}/${projectName}/pdfs`,
        });

        // Return the signed URL for the PDFs folder
        return urlResponse.data.urls;
    };



    const onDrop = async (acceptedFiles, onStartLoading, onFinishLoading, onUpdateProjects, fileRejections) => {

        if (!projectName || projectName === '') {
            console.log('Project name is not defined. Skipping file upload.');
            return;
        }
        try {
            if (onStartLoading) {
                onStartLoading();
            }
            const url = await handleFileUpload(userID, projectName, acceptedFiles);
            setDirectoryName(url);

            if (onDirectoryChange) {
                onDirectoryChange(url);
            }
            if (onUpdateProjects) {
                onUpdateProjects();
            }
        } catch (error) {
            console.error(error);
        } finally {
            if (onFinishLoading) {
                onFinishLoading();
            }
        }
    };

    return (
        <div className='dropzone'>
            <Dropzone
                onDrop={(acceptedFiles) => onDrop(acceptedFiles, onStartLoading, onFinishLoading)}
                accept={acceptedFileType}
                maxFiles={maxFiles}  // Set the maximum number of files to 1
                disabled={isDisabled}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div
                        className={`inp ${isDisabled ? "disabled" : ""}`}
                        {...getRootProps({ onClick: projectName ? undefined : (e) => e.stopPropagation() })}
                    >
                        <input {...getInputProps()} />
                        <p>
                            {projectName
                                ? "Drag and drop a file here, or click to select a file."
                                : "Disabled (select a contract first)"}
                        </p>

                        <img
                            style={{ height: '45px', float: 'inherit', margin: '0px 0px 4px 0px' }}
                            src={addFile} alt="AddFile"
                        />
                    </div>
                )}
            </Dropzone>


        </div>
    );
}

export default PdfUpload;


