import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { ResponsiveContainer, ResponsiveContainer1, ResponsiveElement1, ResponsiveElement2, ResponsiveImage } from '../../twintig/ResponsiveComponents';
import { ModalEx, ModalExW } from '../utils/ModalEx';
import UsageBar from '../utils/UsageBar';
import { AppContext } from '../../AppContext'

import ReactMarkdown from 'react-markdown';
import {
    db,
    doc,
    getDoc,
} from '../../Firebase';

import "../../twintig/tw.css";
import '../css/GPTApp.css';

import qtig from '../../assets/qtig.svg';

import t1 from '../../assets/qtig/t1.svg';
import t2 from '../../assets/qtig/t2.svg';

import qd1 from '../../assets/qtig/qd1.svg';
import qd2 from '../../assets/qtig/qd2.svg';
import qd3 from '../../assets/qtig/qd3.svg';
import qd4 from '../../assets/qtig/qd4.svg';

import qdt from '../../assets/qtig/qdt.png';
import adm from '../../assets/qtig/adm.svg';

import { FaAngleDoubleUp } from 'react-icons/fa';



function QHomeDemo() {

    const { userID, userStatus } = useContext(AppContext).contextValue;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [disclaimerContent, setDisclaimerContent] = useState("");

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'globalData', 'disclaimer'); 
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setDisclaimerContent(data.content);
                } else {
                    console.log("Document does not exist!");
                }
            } catch (error) {
                console.log("Error fetching standard questions: ", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{
            backgroundColor: 'var(--backColor)',
            margin: '0',
            padding: '0',
        }}>
            <ResponsiveContainer1>
                <ResponsiveElement2>
                    <div className="Tw-text Tw-text1" style={{ backgroundColor: '#020202' }}>
                        <p>Welcome to <img src={qtig} alt="qtig" className="qtigInLine" />!</p>
                    </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                    <ResponsiveImage src={qdt} alt="image1" />
                </ResponsiveElement1>
            </ResponsiveContainer1>
            <ResponsiveContainer>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>This is a beta version (<span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow2}>disclaimer</span>).</p>
                        <p style={{ color: '#202020' }}>This version is available for testing purposes before commercial release.</p>
                     </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                        <ResponsiveImage src={qd1} alt="image1" />
                </ResponsiveElement1>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement1>
                    <ResponsiveImage src={qd2} alt="image2" />
                </ResponsiveElement1>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>Create your folders and upload your PDF <span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow}>documents</span>. </p>
                        <p style={{ color: '#202020' }}>It is important that the pdf files have been passed the OCR first.
                        <br />The accuracy of the answers will depend on the quality of the documents.</p>
                    </div>
                </ResponsiveElement2>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>The uploaded files must be <span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow1}>processed</span> and synchronised in the cloud.
                            <br />The process of synchronisation can take a few minutes depending on the number and size of the documents.</p>
                    </div>
                </ResponsiveElement2>
                <ResponsiveElement1>
                    <ResponsiveImage src={qd3} alt="image3" />
                </ResponsiveElement1>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement1>
                    <ResponsiveImage src={qd4} alt="image4" />
                </ResponsiveElement1>
                <ResponsiveElement2>
                    <div className="Tw-text">
                        <p style={{ color: '#202020' }}>If you want to ask questions related to your documents,
                            you must first select a folder under 'Docs' in the top menu and check that the documents are synchronised.
                            <br /><br />If you want to ask general questions you can use the General knowledge QA.
                            <br /><br />Regardless of the language of your document, you can ask questions in any language and your response will be given in that language.
                            <br /><br /><span style={{ textDecoration: "underline", cursor: "pointer", color: "#F06E1E" }} onClick={handleShow3}>Check your usage</span>
                        </p>
                    </div>
                </ResponsiveElement2>
            </ResponsiveContainer>
            <ResponsiveContainer>
                <ResponsiveElement2>
                </ResponsiveElement2>
                <ResponsiveElement1>
                </ResponsiveElement1>
            </ResponsiveContainer>
            <div className="scroll-top" onClick={scrollToTop}>
                <FaAngleDoubleUp  title="back to top" size={30} />
                <p>back to top</p>
            </div>
            {/* ADMINISTRATOR Section */}
            {userStatus === "admin" && (
                <div style={{
                    backgroundColor: 'var(--backColor)',
                    margin: '0',
                    padding: '0 0 6vh 0',
                }}>
                    <ResponsiveContainer>
                        <ResponsiveElement1>
                            <ResponsiveImage src={adm} alt="image" />
                        </ResponsiveElement1>
                        <ResponsiveElement2 style={{
                            backgroundColor: '#CACBC8',
                        }}>
                            <div className="Tw-text">
                                <div className="DashBoardLink">
                                    <Link to="/qtig/admin" className="links-text">Administrator</Link>
                                </div>
                            </div>
                        </ResponsiveElement2>
                    </ResponsiveContainer>
                </div>
            )}

            <ModalEx
                show={show}
                title='Pdf Document'
                handleClose={handleClose}
                imgSrc={t1}
                explanation="Please note that QTIG is specifically designed for construction contracts and related documents.
                If you upload a document that isn' t a contract, it will undergo validation, and if deemed irrelevant, will be deleted.
                Also, keep in mind that this trial version allows the upload of only one PDF file at a time.

                All files you upload, the processed documents, as well as the questions and answers generated within the app, are securely stored on GOOGLE CLOUD and FIREBASE - renowned platforms known for their rigorous security protocols.
                If you choose to delete your uploaded PDF, all related processed information will also be removed."
                confirmLabel='Close'
            />
            <ModalEx
                show={show1}
                title='Document process'
                handleClose={handleClose1}
                imgSrc={t2}
                explanation='The uploaded document needs to be processed in order to be understable to the machine.
                This process is called embedding.
                Word Embeddings in Natural Language Processing is a technique where individual words are represented as real-valued vectors
                in a lower-dimensional space and captures inter-word semantics.
                '
                confirmLabel='Close'
            />
            <ModalExW
                show={show2}
                title='Disclaimer for trial version'
                handleClose={handleClose2}
                explanation={<ReactMarkdown>{disclaimerContent}</ReactMarkdown>}
                confirmLabel='Agree'
            />
            <ModalExW
                show={show3}
                title='Trial version usage'
                handleClose={handleClose3}
                explanation={<UsageBar userID={userID} />}
                confirmLabel='Got it!'
            />
      </div>
    );
}

export default QHomeDemo; 