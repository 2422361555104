import React, { createContext, useState, useEffect } from "react";
import {
    auth,
    storage,
    db,
    onSnapshot,
    getDoc,
    doc,
    getDocs,
    query, 
    where,
    getDownloadURL,
    collection,
    ref,
    listAll
} from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export const AppContext = createContext(null);

export const AppContextProvider = ({ children }) => {
    const [userID, setUserID] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [csvExists, setCsvExists] = useState(false);
    const [syncExists, setSyncExists] = useState(false);
    const [user] = useAuthState(auth);
    const [userStatus, setUserStatus] = useState(null);
    const [userGroup, setUserGroup] = useState(null);
    const [dropVal, setDropVal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [basePath, setBasePath] = useState(null);
    const [directoryStructure, setDirectoryStructure] = useState([]);
    const [isLoadingProjects, setIsLoadingProjects] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);

    const getCsvFile = async (projectName) => {
        try {
            const storageRef = ref(storage, `users/${userID}/${projectName}`);
            const files = await listAll(storageRef);
            const csvFile = files.items.find((file) => file.name.endsWith('.csv'));
            if (csvFile) {
                setCsvExists(true); // Set to true if CSV exists
                return;
            }
            // If the above if-condition isn't met, it means there's no CSV
            setCsvExists(false); // Set to false if no CSV is found

        } catch (error) {
            console.error("Error fetching CSV file:", error);
            setCsvExists(false); // Set to false also when an error occurs
        }
        return null;
    };
    const verifySync = async (projectName) => {
        try {

            // Fetch user documents sync
            const syncDoc = projectName.split('/').join('_');

            const userDocsRef = collection(db, basePath, "documents");
            const querySnapshot = await getDocs(userDocsRef);
            const matchingDocs = [];

            querySnapshot.forEach((doc) => {
                // Check if the document ID contains the 'syncDoc' string
                if (doc.id.includes(syncDoc)) {
                    matchingDocs.push(doc.id);
                }
            });

            // Now 'matchingDocs' contains the IDs of documents matching the criteria
            if (matchingDocs.length > 0) {
                setSyncExists(true);
            } else {
                setSyncExists(false);
            }

        } catch (error) {
            console.error("Error fetching Sync data:", error);
            setSyncExists(false); // Set to false also when an error occurs
        }
        return null;
    };

    useEffect(() => {
        if (user) {
            setUserID(user.uid);
            setIsLoggedIn(true);

            // Fetch user status and group
            const fetchUserStatus = async () => {
                
                const docRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    data.status && setUserStatus(data.status);
                    data.groups && setUserGroup(data.groups);
                    setBasePath(data.groups && data.groups.length > 0 ? `users/${data.groups[0]}` : `users/${user.uid}`);
                }
                
                setLoading(false);
            };

            fetchUserStatus().catch(error => {
                console.error(error);
                setLoading(false);
            });


            const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
                if (doc.exists()) {
                    // Do something with the user document data
                } else {
                    console.log("User document does not exist!");
                }
            });
                return unsubscribe;
        } else {
            setUserID(null);
            setIsLoggedIn(false);
            setUserStatus(null);
            setUserGroup(null);
            setLoading(false);
            setBasePath(null);
        }
    }, [user]);

    useEffect(() => {
        if (projectName) {
            getCsvFile(projectName);
            verifySync(projectName);
        }
    }, [projectName, basePath]);


    const fetchHierarchy = async (basePath) => {
        const storageRef = ref(storage, basePath);
        const result = await listAll(storageRef);

        // Recursively fetch contents for each prefix (folder)
        const folders = await Promise.all(result.prefixes.map(async (folderRef) => {
            const path = folderRef.fullPath;
            const children = await fetchHierarchy(path);  // Recursion
            return {
                type: 'folder',
                name: folderRef.name,
                path: path,
                children: children
            };
        }));

        // Map files directly since they don't have sub-contents
        const files = await Promise.all(result.items.map(async (fileRef) => {
            const fileUrl = await getDownloadURL(fileRef);  // Fetch the download URL for each file
            return {
                type: 'file',
                name: fileRef.name,
                path: fileRef.fullPath,
                url: fileUrl  // Include the file URL in the returned object
            };
        }));

        return [...folders, ...files];
    };

    const fetchAvailableProjects = async (basePath) => {
        setIsLoadingProjects(true);
        try {
            const hierarchy = await fetchHierarchy(basePath);
            setDirectoryStructure(hierarchy);
        } catch (error) {
            console.error("Error fetching hierarchical structure:", error);
        } finally {
            setIsLoadingProjects(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchAvailableProjects(basePath);
        }
    }, [basePath]);


    const contextValue = {
        user,
        userID,
        isLoggedIn,
        projectName,
        setProjectName,
        dropVal,
        setDropVal,
        getCsvFile,  
        csvExists,
        verifySync,
        syncExists,
        setSyncExists,
        userStatus,
        userGroup,
        setUserStatus,
        loading,
        basePath,
        directoryStructure,
        setDirectoryStructure,
        isLoadingProjects,
        setIsLoadingProjects,
        fetchAvailableProjects,
        updateCount,
        setUpdateCount,
    };

    return (
        <AppContext.Provider value={{ contextValue }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
