import { doc, setDoc, updateDoc , db } from "../Firebase"; 

export async function addGlobalData() {
    // Reference to the globalData document
    const globalDataRef = doc(db, "globalData", "config");

    // Data to add
    const data = {
        pricing: [
            {
                model: "GPT-3.5 Turbo",
                prices: {
                    emb: 0.0001,
                    input: 0.0010,
                    output: 0.0020,
                },
            },
            {
                model: "GPT-4 Turbo",
                prices: {
                    emb: 0.0001,
                    input: 0.01,
                    output: 0.03,
                },
            },
        ],

        standardQuestions: [
            {
                name: "Legal",
                questions: [
                    "Are there any indemnification clauses?",
                    "What are the termination clauses?",
                    "What is the dispute resolution mechanism?",
                    "What are the consequences of a breach of contract?",
                    "What are the warranties and guarantees provided?",
                    "Are there any limitations of liability?",
                    "What are the insurance requirements?",
                    "Do any subcontractors need to be pre-approved?",
                    "Are there any clauses related to intellectual property rights?",
                    "Are there any specific regulatory or legal compliance requirements?",
                    "What is the governing law of the contract?",
                    "Are there any force majeure clauses?",
                    "Do any local labor laws apply to the contract?"
                ]
            },
            {
                name: "Commercial",
                questions: [
                    "What is the contract price?",
                    "What are the payment terms?",
                    "Are there any performance incentives or penalties?",
                    "What are the delivery timelines and milestones?",
                    "What are the acceptance criteria?",
                    "What are the key performance indicators?",
                    "What is the process for change orders?",
                    "What are the contract extension or renewal terms?",
                    "Are there any exclusivity clauses?",
                    "What are the requirements for subcontracting?",
                    "Are there any provisions for variations in the scope of work?",
                    "What are the terms for shipping and delivery?",
                    "Are there any liquidated damages clauses?"
                ]
            },
            {
                name: "Financial",
                questions: [
                    "Are there any advance payments required?",
                    "What are the terms for invoicing and payment?",
                    "Are there any financial guarantees or bonds required?",
                    "Is a Performance Bond required, and if yes, in what amount?",
                    "What are the terms for currency conversion?",
                    "Are there any price escalation or adjustment clauses?",
                    "What are the financial penalties for delays or non-performance?",
                    "Are there any provisions for cost-sharing or cost-plus arrangements?",
                    "What are the tax implications of the contract?"
                ]
            },
            {
                name: "Technical",
                questions: [
                    "What are the technical specifications of the project?",
                    "Are there any specific industry standards or certifications required?",
                    "What are the quality assurance and control requirements?",
                    "What are the testing and inspection procedures?",
                    "What are the commissioning and handover requirements?",
                    "Are there any requirements for training or technical support?",
                    "Are there any specific requirements for materials or equipment?",
                    "What are the health, safety, and environmental requirements?",
                    "What are the requirements for documentation and reporting?",
                    "Are there any provisions for ongoing maintenance or warranty support?",
                    "What are the requirements for data security and privacy?"
                ]
            },
            {
                name: "General",
                questions: [
                    "Who are the parties to the contract?",
                    "Who are the parties representatives?",
                    "Wich are the documents of the contract and their priorities?",
                    "Please summary steps outlined in clause xxx",
                    "What is the overall timeline of the project?",
                    "Are there any cultural or language considerations?",
                    "What are the requirements for permits, licenses, or other regulatory approvals?",
                    "Are there any specific requirements for local content or sourcing?",
                    "What are the expectations for project management and communication?",
                    "Are there any political, social, or environmental risks to consider?",
                    "What are the contingency plans for unforeseen events or delays?",
                    "Are there restriction for photos or videos of the site?"
                ]
            },
            {
                name: "Spanish",
                questions: [
                    "Hay clausulas de cambio de ley?",
                    "Quienes son las partes del contrato?",
                    "Que notificaciones del contratista son obligatorias?",
                    "Cuales y de que importe son los avales requeridos?",
                    "Hay clausulas de fuerza mayor en el contrato?",
                    "Quien es responsable de la poliza de todo riesgo de construccion?",
                ]
            }

        ],
        summaryQuestions: [
            { id: 1, topic: 'Change in law', contentCl: 'Data 1', question: 'Discuss change in law clauses, if any' },
            { id: 2, topic: 'Geological Risk', contentCl: 'Data 2', question: 'Who assumes the geological risk?' },
            { id: 3, topic: 'Payments', contentCl: 'Data 3', question: 'Briefly describe the terms for invoicing and payment.' },
            { id: 4, topic: 'Contractor\'s Maximum liability', contentCl: 'Data 4', question: 'Which is the maximum Contractor\'s liability, if any?' },
            { id: 5, topic: 'Bond and Guarantees', contentCl: 'Data 5', question: 'Describe the Bonds or Gurantees required by the Contract and their amounts, if any' },
            { id: 6, topic: 'Required Insurances', contentCl: 'Data 6', question: 'Describe the insurances required by the Contract, if any' },
            { id: 7, topic: 'Dispute Resolution', contentCl: 'Data 7', question: 'What is the dispute resolution mechanism?' },
            { id: 8, topic: 'Governing Law', contentCl: 'Data 8', question: 'What is the governing law of the contract?' },
            { id: 9, topic: 'Force Majeure', contentCl: 'Data 9', question: 'Describe clause of Force Majeur, if any' },
            { id: 10, topic: 'Termination', contentCl: 'Data 10', question: 'What are the causes of termination?' },

        ],
    };
    const disclaimerText = `
Please read this disclaimer ("**Disclaimer**") carefully before using the trial version of QTIG ("**the App**") operated by TWINTIG SRL ("**us,**" "**we,**" or "**our**").

### 1. Nature of the Trial Version
The trial version of the App is provided free of charge and is intended for testing and evaluation purposes only. Features and functionalities might be limited or different compared to the full version.

### 2. No Warranty
The trial version of the App is provided "as is" without any representations or warranties of any kind, either expressed or implied. We do not guarantee its accuracy, completeness, or usefulness. You use the App at your own risk.

### 3. Limitation of Liability
In no event shall TWINTIG, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages resulting from the use of or inability to use the trial version of the App.

### 4. Data & Content
Given the trial nature of this App, we do not guarantee the preservation or security of data or content you input. We strongly advise against uploading any confidential, sensitive, or critical information.

### 5. Feedback & Reporting
Users of the trial version are encouraged, but not obligated, to provide feedback regarding the App. Any feedback provided will be considered non-confidential and non-proprietary.

### 6. Termination
We reserve the right to terminate or suspend access to the trial version of the App at any time without notice.

### 7. Changes
We reserve the right to modify or replace this Disclaimer at any time.

By using the trial version of the App, you agree to this Disclaimer. If you disagree with any part of this Disclaimer, please discontinue use of the trial version immediately.

Last Updated: **October 3, 2023**
`;

    const dataObject = {
        content: disclaimerText
    };
    
  // Adding data to Firestore
    await setDoc(doc(db, 'globalData', 'disclaimer'), dataObject);

    await setDoc(globalDataRef, data);
}


