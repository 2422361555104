import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../css/Dialog.css';

function AlertDialog({ show, title, message, onConfirm, confirmLabel, dialogClass = "modalD-dialog" }) {
    return (
        <Modal className="modalD" show={show} onHide={onConfirm} dialogClassName={dialogClass}>
            <Modal.Header className="modalHeader" closeButton>
                <Modal.Title className="modalTitle" >{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody" >{message}</Modal.Body>
            <Modal.Footer className="modalFooter" >
                <Button className="confirmButton" variant="dark" onClick={onConfirm}>
                    {confirmLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlertDialog;