import { storage, ref, listAll, getDownloadURL } from '../../Firebase';
import { db, doc, getDoc } from '../../Firebase';

const fetchPDFs = async (path, basePath) => {
    try {
        const folderRef = ref(storage, path);
        const fileList = await listAll(folderRef);

        // Filter out the PDFs and check if they are synchronized and if they have a 'docSummary' field
        const pdfFilesPromises = fileList.items
            .filter(item => item.name.toLowerCase().endsWith('.pdf'))
            .map(async (item) => {
                // Generate Firestore document reference name based on item path
                const docRefName = `${item.fullPath.replace(/\.pdf$/, '').split('/').slice(2).join('_')}`;
                const docRef = doc(db, basePath, 'documents', docRefName);
                const docSnap = await getDoc(docRef);

                // Fetch the download URL for each file
                const fileUrl = await getDownloadURL(item);

                if (docSnap.exists()) {
                    // Return the name and the value of docSummary (or false if not present)
                    const hasDocSummary = docSnap.data().hasOwnProperty('docSummary') ? docSnap.data().docSummary : false;
                    return { url: fileUrl, name: item.name, summary: hasDocSummary };
                } else {
                    // If the document does not exist, do not include it in the results
                    return null;
                }
            });

        // Resolve all promises, filter out null values (where the document didn't exist)
        const pdfFiles = (await Promise.all(pdfFilesPromises)).filter(item => item !== null);

        return pdfFiles;
    } catch (error) {
        console.error("Error fetching PDF files:", error);
        throw error;
    }
};

export default fetchPDFs;


