import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, listAll, getDownloadURL, deleteObject, uploadString } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInAnonymously,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    deleteDoc,
    getFirestore,
    query,
    getDoc,
    getDocs,
    updateDoc,
    arrayUnion,
    collection,
    doc,
    where,
    addDoc,
    onSnapshot,
    runTransaction,
    setDoc,
    serverTimestamp,
    startAfter,
    writeBatch,
} from "firebase/firestore";


// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);
const getSignedUrlCallable = httpsCallable(functions, 'getSignedUrl');
const getSignedUrlFolderCallable = httpsCallable(functions, 'getSignedUrlsForFolder');

const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

async function setUserDataAndContract(uid, email) {
    const userDoc = setDoc(doc(db, "users", uid), {
        email, // user email
        status: "demo",
        groups: [],
        totalAmount: 0,
        timeLog: serverTimestamp()  // timestamp generated by Firestore server
    });

    const contractDoc = setDoc(doc(db, "users", uid, "contracts", "demo"), {
        contractName: "demo",
        qas: [],
        docTokensE: 0,
        qasTokensE: 0,
        qasTokensI: 0,
        qasTokensO: 0,
        timeLog: serverTimestamp(),
    });

    await Promise.all([userDoc, contractDoc]);
}

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const uid = user.uid;
        const email = user.email;
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            setUserDataAndContract(uid, email)
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        const uid = user.uid;
        const userEmail = user.email;
        setUserDataAndContract(uid, userEmail)
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    signOut(auth);
};

export {
    arrayUnion,
    auth,
    collection,
    db,
    deleteDoc,
    deleteObject,
    doc,
    getDoc,
    getDocs,
    getDownloadURL,
    getSignedUrlCallable,
    getSignedUrlFolderCallable,
    listAll,
    logInWithEmailAndPassword,
    logout,
    onSnapshot,
    query,
    ref,
    registerWithEmailAndPassword,
    runTransaction,
    sendPasswordReset,
    serverTimestamp,
    setDoc,
    signInWithGoogle,
    startAfter,
    storage,
    updateDoc,
    uploadBytes,
    uploadString,
    where,
    writeBatch,
};
