import React, { useState, useEffect } from 'react';
import {
    db,
    doc,
    getDoc,
    query,
    collection,
    getDocs
} from '../../Firebase';

const UsageBar = ({ userID }) => {

    const [totalAmount, setTotalAmount] = useState(0);
    const [transactionCount, setTransactionCount] = useState(0);
    const userDocRef = doc(db, "users", userID); // Lifted to the component scope

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userDocSnapshot = await getDoc(userDocRef);
                if (userDocSnapshot.exists()) {
                    setTotalAmount(userDocSnapshot.data().totalAmount || 0);
                }
            } catch (error) {
                console.error("Error fetching total amount:", error);
            }
        };

        fetchData();
    }, [userID]);

    const percentage = totalAmount * 150;

    useEffect(() => {
        const fetchTransactionCount = async () => {
            try {
                const tokenHistoryCollectionRef = collection(userDocRef, "tokenHistory");
                const q = query(tokenHistoryCollectionRef);
                const snapshot = await getDocs(q);
                setTransactionCount(snapshot.size);
            } catch (error) {
                console.error("Error fetching transaction count:", error);
            }
        };

        fetchTransactionCount();
    }, [userDocRef]);  // Dependency on userDocRef 

    return (
        <div>
            <div style={{
                margin: '30px 0',
                width: '100%',
                height: '40px',
                backgroundColor: 'rgb(100, 100, 100)',
                borderRadius: '5px',
                overflow: 'hidden'
            }}>
                <div style={{
                    width: `${percentage}%`,
                    height: '100%',
                    backgroundColor: 'var(--orange)',
                    textAlign: 'right',
                    paddingRight: '10px',
                    lineHeight: '40px',
                    color: 'white'
                }}>
                    {percentage.toFixed(1)}% 
                </div>
            </div>
            <div style={{
                textAlign: 'left',
                color: 'rgb(32, 32, 32)'
            }}>
                Total number of transactions: {transactionCount}.
            </div>
        </div>
    );
};

export default UsageBar;