import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, } from "../../Firebase";


function Dashboard() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    //Can be used to fetch data of the user


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("login");

    }, [user, loading]);

    return

}

export default Dashboard;
